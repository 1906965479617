import React from "react";
import { NavLink } from "react-router-dom";
import Poster1 from "../assets/images/poster1.png";
import Poster2 from "../assets/images/poster2.png";

function LearnPage() {
  const openForm = (e) => {
    e.preventDefault();
    window.open("https://forms.gle/px42bZS8wahcz5rH8");
  };
  return (
    <section>
      <div className="section-learn">
        <img
          src={Poster1}
          alt="poster1"
          loading="lazy"
          className="section-learn-image"
        />
        <img
          src={Poster2}
          alt="poster2"
          loading="lazy"
          className="section-learn-image"
        />

        <div className="section-learn-button">
          <h4 className="section-learn-button-heading">
            Fill this form to prepare for software engineering and machine
            learning interviews from professors of top universities and industry
            professionals.
          </h4>
          <div className="section-learn-button-buttons">
            <a className="primary-button-shadow" href="#" onClick={openForm}>
              Enroll in Kyron Academy
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LearnPage;
