function ReturnPolicy() {
  return (
    <section>
      <div className="section-tos">
        <h3 className="section-tos__heading">Kyron Return Policy</h3>
        <p className="section-tos__subheading">
          No Refunds. All sales are final, and the Company does not offer any
          money-back guarantees. You recognize and agree that you shall not be
          entitled to a refund for any purchase under any circumstances.
        </p>
      </div>
    </section>
  );
}

export default ReturnPolicy;
