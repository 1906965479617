import React from "react";
import { Hiring2 } from "../components/hiring";
import Footer from "../components/footer";

import Benesse from "../assets/images/benesse.png";
import Berlitz from "../assets/images/berlitz.png";
import Slti from "../assets/images/slti.png";
import Adobe from "../assets/images/adobe.png";
import Apna from "../assets/images/apna.png";

function Customers() {
  const openBenesse = () => {
    window.open("https://www.benesse-hd.co.jp/en/");
  };
  const openBerlitz = () => {
    window.open("https://www.berlitz.com/");
  };
  const open2lti = () => {
    window.open("https://www.linkedin.com/company/2lti/");
  };
  const openApna = () => {
    window.open("https://apna.co/");
  };
  const openAdobe = () => {
    window.open("https://www.adobe.com/in/");
  };
  return (
    <section>
      <div className="section-customer">
        <h3 className="section-customer__heading">Our Customers</h3>
        <p className="section-customer__subheading">
          Some of our very esteemed clients.
        </p>

        <div className="customer-cards">
          <div className="customer-card" onClick={openAdobe}>
            <img
              loading="lazy"
              className="customer-card__image"
              src={Adobe}
              alt="Adobe"
            />
            <h4 className="customer-card__title">Adobe</h4>
            <div className="customer-card__link">Read more...</div>
          </div>
          <div className="customer-card" onClick={openBenesse}>
            <img
              loading="lazy"
              className="customer-card__image"
              src={Benesse}
              alt="Benesse"
            />
            <h4 className="customer-card__title">Benesse Corporation</h4>
            <div className="customer-card__link">Read more...</div>
          </div>
          <div className="customer-card" onClick={open2lti}>
            <img
              loading="lazy"
              className="customer-card__image"
              src={Slti}
              alt="Slti"
            />
            <h4 className="customer-card__title">
              Second Language Testing, Inc.
            </h4>
            <div className="customer-card__link">Read more...</div>
          </div>
          <div className="customer-card" onClick={openBerlitz}>
            <img
              loading="lazy"
              className="customer-card__image"
              src={Berlitz}
              alt="Berlitz"
            />
            <h4 className="customer-card__title">Berlitz Corporation</h4>
            <div className="customer-card__link">Read more...</div>
          </div>
          <div className="customer-card" onClick={openApna}>
            <img
              loading="lazy"
              className="customer-card__image"
              src={Apna}
              alt="Apna"
            />
            <h4 className="customer-card__title">Apna</h4>
            <div className="customer-card__link">Read more...</div>
          </div>
          {/*   <div className="customer-card">
            <img
              loading="lazy"
              className="customer-card__image"
              src={Berlitz}
              alt="Berlitz"
            />
            <h4 className="customer-card__title">Berlitz Corporation</h4>
            <div className="customer-card__link">Read more...</div> 
          </div>*/}
        </div>
        <Hiring2 />
      </div>
      <Footer />
    </section>
  );
}

export default Customers;
