import React from "react";
import { NavLink } from "react-router-dom";
import errorPoster from "../assets/icons/error.svg";

function Error() {
  return (
    <section className="section-error container">
      <img
        className="section-error-image"
        src={errorPoster}
        alt="ErrorPoster"
      />
      <h3 className="section-error-heading">
        Ooops! The page you were looking for could not be found
      </h3>
      <button className="section-error-button">
        <NavLink className="section-error-button-text" to="/">
          Go back to home
        </NavLink>
      </button>
    </section>
  );
}

export default Error;
