import React from "react";
import TextTransition, { presets } from "react-text-transition";
import GlobeComponents from "../components/globeComponent";
import { Hiring1 } from "../components/hiring";
import Footer from "../components/footer";
import { NavLink, useNavigate } from "react-router-dom";

import Testimonials from "../components/testimonials";

// import Skills from "../assets/icons/skill.png";
// import Assessment from "../assets/icons/assessment.png";
// import Platform from "../assets/icons/platform.png";
// import Tool from "../assets/icons/tool.png";
// import Proctor from "../assets/icons/proctor.png";
// import Tracking from "../assets/icons/tracking.png";
import All from "../assets/images/all.png";

import Benesse from "../assets/images/benesse.png";
import Berlitz from "../assets/images/berlitz.png";
import Slti from "../assets/images/slti.png";
import Adobe from "../assets/images/adobe.png";
import Apna from "../assets/images/apna.png";

function HomePage() {
  const history = useNavigate();
  const TEXTS = ["reliable", "validated", "trusted"];

  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      2000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  function handleClick(id) {
    // history(`/product#${id}`);
    history("/product");
  }
  return (
    <section>
      <div className="section-home">
        <div className="section-home__detail">
          <h4 className="heading-primary heading-primary--main u-margin-bottom-medium">
            {" "}
            Hiring Talent is now <br />
            <span className="heading-primary--primary">
              <TextTransition inline springConfig={presets.wobbly}>
                {TEXTS[index % TEXTS.length]}
              </TextTransition>
            </span>
          </h4>

          {/* <h4 className=" heading-primary heading-primary--main u-margin-bottom-small">
        </h4> */}
          <p className="heading-tertiary heading-tertiary--main ">
            Kyron is a state-of-the-art learning, skilling and assessments
            platform built on the foundation of integrity, validity and academic
            trust. Designed by computer science researchers, organizational
            behaviour and human psychology experts the Kyron ecosystem{" "}
            <b>trains</b>, <b>assesses</b>, and
            <b> validates</b> a candidate through a peer-to-peer reward-based
            validation system.
            <br /> <br />
            Kyron journey starts with creating unique, personalized tests based
            on the foundations of competency mapping. Results and test integrity
            are then signed off individually by human and AI proctors post which
            a candidate’s profile is created. The candidate's entire profile
            with skill scores is then added to the Kyron blockchain, ready for
            our industry partners to interview and hire.
          </p>
          <NavLink className="primary-button-shadow-small" to="/recruit">
            Start Hiring
          </NavLink>
          <NavLink className="primary-button-shadow-small" to="/academy">
            Start Learning
          </NavLink>
          <NavLink className="primary-button-shadow-small" to="/aicts">
            Start Practicing
          </NavLink>
        </div>
        <div className="section-home__globe">
          <GlobeComponents />
        </div>
      </div>
      <h3 className="heading">Our Clients</h3>
      <div className="home-data">
        <div className="home-data__container">
          <div className="home-data__panel">
            <img loading="lazy" src={Benesse} alt="Benesse" />
          </div>
          <div className="home-data__panel">
            <img
              loading="lazy"
              src={Berlitz}
              alt="Berlitz"
              className="home-data__panel-large"
            />
          </div>
          <div className="home-data__panel">
            <img
              loading="lazy"
              src={Adobe}
              alt="Adobe"
              className="home-data__panel-large"
            />
          </div>
          <div className="home-data__panel">
            <img loading="lazy" src={Slti} alt="Slti" />
          </div>
          <div className="home-data__panel">
            <img loading="lazy" src={Apna} alt="Apna" />
          </div>
          {/* <div className="home-data__panel">
            <h3 className="home-data__panel-heading">
              State-of-the-art exam platform
            </h3>
            <h2 className="home-data__panel-subheading">36%</h2>
          </div>
          <div className="home-data__panel">
            <h3 className="home-data__panel-heading">100% human proctored</h3>
            <h2 className="home-data__panel-subheading">36%</h2>
          </div>
          <div className="home-data__panel">
            <h3 className="home-data__panel-heading">
              AI-based behavioral check
            </h3>
            <h2 className="home-data__panel-subheading">36%</h2>
          </div>
          <div className="home-data__panel">
            <h3 className="home-data__panel-heading">
              Suitable candidate onboarding
            </h3>
            <h2 className="home-data__panel-subheading">36%</h2>
          </div> */}
        </div>
      </div>

      <section className="skill-section u-margin-top-big u-margin-bottom-big ">
        <div className="skill-section-box">
          <div className="skill-section-box-imagebox">
            <img
              loading="lazy"
              className="skill-section-box-image"
              src={All}
              alt="All"
            />
          </div>
          <ul className="skill-section-box-list">
            <li className="skill-section-box-list-item">
              {/* Magnet for your needle in our talent haystack. */}
            </li>
            <li className="skill-section-box-list-item">
              AI-based skills matching:
              <ul className="skill-section-box-sublist">
                <li className="skill-section-box-sublist-item">
                  93% of the millennials look for job suiting their skill sets
                </li>
              </ul>
            </li>
            <li className="skill-section-box-list-item">
              Kyron state of the art fully customizable platform:
              <ul className="skill-section-box-sublist">
                <li className="skill-section-box-sublist-item">
                  94% of recruiters say that using a right recruitment software
                  has positively impacted their hiring process.
                </li>
              </ul>
            </li>
            <li className="skill-section-box-list-item">
              Human+AI proctored exam platform:
              <ul className="skill-section-box-sublist">
                <li className="skill-section-box-sublist-item">
                  72% of the candidates admitted cheating in some form during
                  virtual assessments.
                </li>
              </ul>
            </li>
            <li className="skill-section-box-list-item">
              AI-based behavioral check:
              <ul className="skill-section-box-sublist">
                <li className="skill-section-box-sublist-item">
                  89% recruiters admitted difficulty in accessing behaviour
                  during HR rounds
                </li>
              </ul>
            </li>
            <li className="skill-section-box-list-item">
              Suitable candidate onboarding:
              <ul className="skill-section-box-sublist">
                <li className="skill-section-box-sublist-item">
                  68% of employees have mentioned they have experienced poor
                  onboarding experience.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </section>

      {/* <div className="home-assement">
        <h3 className="home-assement-heading">Our Assessment Tools</h3>
        <div className="home-assement-data">
          <div
            className="home-assement-card"
            onClick={() => handleClick("skill")}
          >
            <img
              loading="lazy"
              className="home-assement-card__image"
              src={Skills}
              alt="Kyron Skill Inventory"
            />
            <h1 className="heading-tertiary heading-tertiary--main">
              Kyron Skill Inventory
            </h1>
          </div>
          <div
            className="home-assement-card"
            onClick={() => handleClick("talent")}
          >
            <img
              loading="lazy"
              className="home-assement-card__image"
              src={Tool}
              alt="Kyron Talent Discovery"
            />
            <h1 className="heading-tertiary heading-tertiary--main">
              Kyron Talent Discovery
            </h1>
          </div>
          <div
            className="home-assement-card"
            onClick={() => handleClick("proctor")}
          >
            <img
              loading="lazy"
              className="home-assement-card__image"
              src={Proctor}
              alt="Kyron Proctoring"
            />
            <h1 className="heading-tertiary heading-tertiary--main">
              Kyron Proctoring
            </h1>
          </div>
          <div
            className="home-assement-card"
            onClick={() => handleClick("platform")}
          >
            <img
              loading="lazy"
              className="home-assement-card__image"
              src={Platform}
              alt="Kyron Exam Platform"
            />
            <h1 className="heading-tertiary heading-tertiary--main">
              Kyron Exam Platform
            </h1>
          </div>
          <div
            className="home-assement-card"
            onClick={() => handleClick("assessment")}
          >
            <img
              loading="lazy"
              className="home-assement-card__image"
              src={Assessment}
              alt="Kyron Competencies Assessment"
            />
            <h1 className="heading-tertiary heading-tertiary--main">
              Kyron Competencies Assessment
            </h1>
          </div>
          <div
            className="home-assement-card"
            onClick={() => handleClick("assessment")}
          >
            <img
              loading="lazy"
              className="home-assement-card__image"
              src={Tracking}
              alt="Kyron Competencies Assessment"
            />
            <h1 className="heading-tertiary heading-tertiary--main">
              Kyron Applicant Tracking
            </h1>
          </div>
        </div>
      </div> */}
      <div className="u-margin-top-big">
        <Testimonials />
      </div>
      <Hiring1 />

      <Footer />
    </section>
  );
}

export default HomePage;
