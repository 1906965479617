import React from "react";
import { Hiring3 } from "../components/hiring";
import Footer from "../components/footer";

function ResourcePage() {
  return (
    <section>
      <div className="section-resource">
        <h3 className="section-resource__heading">Our Projects</h3>
        <p className="section-resource__subheading">
          Here are some of the projects created by us that might help you in
          your busniess.
        </p>

        <div className="resource__1">
          <div className="resource__1-imagebox">
            <img loading="lazy"
              className="resource__1-image"
              src="https://sigsail.com/static/media/essay.bdc02e1d.jpg"
              alt="test 1"
            />
          </div>

          <div className="resource__1-content">
            <h3 className="resource__1-content-heading">
              Automatic Essay and Speech Scoring Systems
            </h3>
            <p className="resource__1-content-subheading">
              Automated essay scoring (AES) is the use of specialized computer
              programs to assign grades to essays written in an educational
              setting. It is a form of educational assessment and an application
              of natural language processing. Its objective is to classify a
              large set of textual entities into a small number of discrete
              categories, corresponding to the possible grades, for example, the
              numbers 1 to 6.
            </p>
          </div>
        </div>
        <div className="resource__2">
          <div className="resource__2-content">
            <h3 className="resource__2-content-heading">
              Automatic Essay and Speech Scoring Systems
            </h3>
            <p className="resource__2-content-subheading">
              Automated essay scoring (AES) is the use of specialized computer
              programs to assign grades to essays written in an educational
              setting. It is a form of educational assessment and an application
              of natural language processing. Its objective is to classify a
              large set of textual entities into a small number of discrete
              categories, corresponding to the possible grades, for example, the
              numbers 1 to 6.
            </p>
          </div>
          <div className="resource__2-imagebox">
            <img loading="lazy"
              className="resource__2-image"
              src="https://sigsail.com/static/media/essay.bdc02e1d.jpg"
              alt="test 2"
            />
          </div>
        </div>
        <Hiring3 />
      </div>

      <Footer />
    </section>
  );
}

export default ResourcePage;
