function PrivacyPage() {
  return (
    <section>
      <div className="section-privacy">
        <h3 className="section-privacy__heading">Kyron Privacy Policy</h3>
        <p className="section-privacy__subheading">
          Data privacy is important to Kyron, and when it comes to your data,
          we’re committed to protecting it and providing transparency about the
          information we collect from you and how we use it. This Privacy Policy
          is designed to let you know the information we collect about you on
          our websites (the “Websites”), including from individuals who register
          to use the Kyron online community for software developers (the “Kyron
          Community”), and individuals who use the Kyron for Work service. This
          Privacy Policy describes Kyron’s privacy practices in relation to the
          use of the Websites, and the related applications and services offered
          by Kyron (collectively, the “Services”), as well as individuals’
          choices regarding use, access and correction of personal
          information.In this Privacy Policy, the terms “Kyron,” “We” and “Us”
          refer to Signal Sciences and Artificial Intelligence Laboratories Pvt
          Ltd, doing business as Kyron.
        </p>
        <h3 className="section-privacy__heading2">Types of Data Collected</h3>
        <p className="section-privacy__subheading">
          Kyron collects, processes, and retains information about you when you
          visit the Websites or use the Services. You may choose to provide
          Kyron with information, such as your name, email address, company
          information, street address, telephone number, or other information,
          to use the Services or so we can follow up with you after your visit.
          If you choose to participate in the Kyron Community, you may choose to
          provide us with additional information such as social media profiles,
          education information, experience, and other information. Certain data
          elements requested of you by the Websites are “mandatory” or
          “required,” and failure to provide this data may make it impossible
          for Kyron to provide its Services to you. In cases where requested
          data elements are not mandatory or required, you may choose not to
          provide the requested data without any consequences to the
          availability or the functioning of the Services. We may combine other
          publicly available information with the information that you provide
          to us to enhance and optimize our Websites and Services for you. Kyron
          may observe your activities, interactions, preferences, and other
          computer and connection information (such as IP address and geographic
          location) relating to your use of the Websites and the Services. Kyron
          may also use log files, cookies and similar technologies to collect
          information about the pages you view, links you click on, and other
          actions you may take when accessing the Websites and Services. For
          more information about our use of these technologies, please see the
          Cookies section below. We may collect and store this information and
          combine this information with other personal information you may have
          provided.
        </p>
        <h3 className="section-privacy__heading2">
          Kyron for Work Service Data
        </h3>
        <p className="section-privacy__subheading">
          Kyron for Work customers may upload data to the Kyron for Work hosted
          Service. Kyron will not share or distribute any such customer data
          except as provided in the contractual agreement between Kyron and
          customer for the provision of the Kyron for Work Service, or as may be
          required by law. If a Kyron for Work customer is located within the
          European Economic Area, the customer is the Controller with respect to
          the Kyron for Work data, and Kyron is a Processor.
        </p>
        <h3 className="section-privacy__heading2">Use of Collected Data</h3>
        <p className="section-privacy__subheading">
          Kyron uses the information we collect about you to:
        </p>
        <ul className="section-privacy__list">
          <li>
            Provide you with the Websites and Services, together with any
            support you may request
          </li>
          <li>Respond to your inquiries or fulfill your requests</li>
          <li>Diagnose Website and Service technical problems</li>
          <li>
            Send you information that we believe may be of interest to you, such
            as Service announcements, newsletters, educational materials, and
            event information
          </li>
          <li>
            Send you administrative information such as notices related to the
            Services or policy changes
          </li>
          <li>
            Understand how the Websites and Services are being used in order to
            enhance and optimize them
          </li>
          <li>Personalize your experience on the Websites</li>
          <li>
            Prevent, detect, mitigate, and investigate fraudulent or illegal
            activity; and
          </li>
          <li>
            As described to you at the point of collection of the information.
          </li>
        </ul>
        <h3 className="section-privacy__heading2">Processing the Data</h3>
        <p className="section-privacy__subheading">
          The information collected by Kyron is stored and processed by Kyron’s
          sub-processor, Amazon Web Services (“AWS”), leveraging AWS data
          centers located within India. Processing of the information collected
          about you is only undertaken for the purposes described in this
          Privacy Policy.
        </p>
        <h3 className="section-privacy__heading2">Processing Details</h3>
        <p className="section-privacy__subheading">
          The information collected by Kyron is stored and processed by Kyron’s
          sub-processor, Amazon Web Services (“AWS”), leveraging AWS data
          centers located within India. Processing of the information collected
          about you is only undertaken for the purposes described in this
          Privacy Policy.
        </p>
        <div className="section-privacy__inside">
          <h3 className="section-privacy__heading2">
            Displaying Content from External Platforms
          </h3>
          <p className="section-privacy__subheading">
            The third-party service described in this section enables Kyron to
            display content to you on the Websites that is hosted on an external
            third party platform. This type of service may collect web traffic
            data in order to optimize delivery of the content on the
            Websites.YouTube Video (Google Inc.) <br /> <br />
            YouTube is a video content visualization service provided by Google
            Inc. that allows the Websites and Services to incorporate content on
            its pages that is hosted on YouTube.Information collected: Cookies
            and usage data.Place of processing: US –{" "}
            <a href="http://www.google.it/intl/en/policies/privacy/">
              Privacy Policy{" "}
            </a>
          </p>

          <h3 className="section-privacy__heading2">Geolocation</h3>
          <p className="section-privacy__subheading">
            The Websites may collect and use location information in order to
            provide location-based services to you. Most browsers and devices
            provide tools to opt out from this feature by default. Information
            collected: geographic location.
          </p>

          <h3 className="section-privacy__heading2">
            Interaction with external social networks and platforms
          </h3>
          <p className="section-privacy__subheading">
            The third-party service described in this section enables Kyron to
            display content to you on the Websites that is hosted on an external
            third party platform. This type of service may collect web traffic
            data in order to optimize delivery of the content on the
            Websites.Facebook Like Button and Social Widgets (Facebook, Inc.){" "}
            <br />
            <br />
            Information collected: Cookies and usage data. Place of processing:
            US –{" "}
            <a href="https://www.facebook.com/privacy/explanation">
              Privacy Policy
            </a>
            Twitter Tweet Button and Social Widgets (Twitter) Information
            collected: Cookies and usage data.Place of processing: US –{" "}
            <a href="https://twitter.com/privacy">Privacy Policy</a>
          </p>

          <h3 className="section-privacy__heading2">
            Registration and Authentication for the Kyron Community
          </h3>
          <p className="section-privacy__subheading">
            The third-party services described in this section enable the
            Websites and Services to register, identify and authenticate you in
            order to access dedicated Services. Facebook Authentication
            (Facebook, Inc.)
            <br />
            <br />
            Information collected: authentication data as specified in the
            Facebook privacy policy.Place of processing: US –{" "}
            <a href="https://www.facebook.com/help/405977429438260">
              Privacy Policy
            </a>
            <br /> <br />
            GitHub Authentication (GitHub Inc.)
            <br />
            Information collected: authentication data as specified in the
            GitHub privacy policy.Place of processing: US –{" "}
            <a href="https://help.github.com/articles/github-privacy-statement/">
              Privacy Policy
            </a>
            <br />
            <br />
            Google OAuth (Google Inc.) <br />
            Information collected: authentication data as specified in the
            Google OAuth privacy policy.Place of processing: US –{" "}
            <a href="https://developers.google.com/accounts/docs/OAuth2">
              Privacy Policy
            </a>
            <br />
            <br />
            LinkedIn Authentication (LinkedIn Corporation) <br />
            nformation collected: authentication data as specified in the
            LinkedIn privacy policy.Place of processing: US –{" "}
            <a href="https://www.linkedin.com/legal/privacy-policy">
              Privacy Policy
            </a>
          </p>
        </div>
        <h3 className="section-privacy__heading2">How We Disclose Your Data</h3>
        <p className="section-privacy__subheading">
          We do not sell, lease, rent or give away the information collected
          about you without your permission. We only disclose your information
          as described below. If we disclose your information, we require those
          we share it with to comply with adequate privacy and confidentiality
          requirements, and security standards.
        </p>
        <div className="section-privacy__inside">
          <h3 className="section-privacy__heading2">Kyron Community</h3>
          <p className="section-privacy__subheading">
            If you are a Kyron Community member, we may list your username on a
            leader board if you participate in a CodeSprint. We may also share
            information about you with a potential employer if you opt for your
            information to be shared in order to connect with Kyron Jobs and
            CodeSprint sponsor companies.
          </p>

          <h3 className="section-privacy__heading2">
            Service Providers Processing Data on Our Behalf
          </h3>
          <p className="section-privacy__subheading">
            We may use contractors and service providers to process the
            information collected about you on our behalf for the purposes
            described in this Privacy Policy. We contractually require service
            providers to keep information secure and confidential and we do not
            allow our data processors to disclose your information to others
            without our authorization, or to use it for their own purposes.
            However, if you have an independent relationship with these service
            providers their privacy statements will apply.
          </p>

          <h3 className="section-privacy__heading2">Law Enforcement</h3>
          <p className="section-privacy__subheading">
            In certain instances, it may be necessary for Kyron to disclose the
            information that we’ve collected about you to government officials
            or otherwise as required by applicable law. No personal data will be
            disclosed to any law enforcement agency or governmental agency
            except in response to:
          </p>
          <ul className="section-privacy__list">
            <li>
              A subpoena, warrant or other process issued by a court of
              competent jurisdiction
            </li>
            <li>
              A legal process having the same consequence as a court-issued
              request for information, in that if Kyron were to refuse to
              provide such information, it would be in breach of local law, and
              it or its officers, executives or employees would be subject to
              liability for failing to honor such legal process; or
            </li>
            <li>
              Where such disclosure is necessary for Kyron to enforce its legal
              rights pursuant to the laws of the jurisdiction from which such
              information was gathered.
            </li>
          </ul>
        </div>

        <h3 className="section-privacy__heading2">
          Cookies and Similar Technologies
        </h3>
        <p className="section-privacy__subheading">
          A cookie is a commonly used automated data collection tool. Cookies
          are small text files that are placed on your computer or device by
          websites that you visit or HTML-formatted emails you open, in order to
          make websites work, or work more efficiently. We may use cookies, web
          beacons, pixel tags, or other similar technologies on the Websites to,
          among other things:
        </p>
        <ul className="section-privacy__list">
          <li>
            Authenticate you as a Kyron Community member or Kyron for Work user;
          </li>
          <li>Collect statistics regarding your usage of the Websites;</li>
          <li>
            Tailor information presented to you based on your usage of the
            Websites;
          </li>
          <li>Help provide additional functionality to the Websites; and</li>
          <li>Analyze use of our Services.</li>
        </ul>
        <p className="section-privacy__subheading">
          You may refuse to accept cookies by activating the appropriate setting
          on your browser. If a cookie blocking setting is selected on your
          browser, however, you may be unable to access certain parts of our
          Website or to receive certain Services.
        </p>

        <h3 className="section-privacy__heading2">Do-Not-Track</h3>
        <p className="section-privacy__subheading">
          There are different ways you can prevent tracking of your online
          activity. One of them is setting a preference in your browser that
          alerts websites you visit that you do not want them to collect certain
          information about you. This is referred to as a Do-Not-Track (“DNT”)
          signal. Please note that our Websites may not recognize or take action
          in response to DNT signals from your browser.
        </p>

        <h3 className="section-privacy__heading2">Managing Your Data</h3>
        <p className="section-privacy__subheading">
          We retain the information that we collect about you for as long as
          your Kyron Community or Kyron for Work account is active or as needed
          to provide you Services. We also retain and use the information
          collected about you as necessary to comply with our legal obligations,
          resolve disputes, prevent and detect fraud, and enforce our
          agreements. We store the information collected about you for as long
          as is necessary for the purpose for which we collect it.
        </p>

        <h3 className="section-privacy__heading2">
          Accessing, Updating, Correcting or Deleting Your Data
        </h3>
        <p className="section-privacy__subheading">
          You are able to view and update much of the information collected
          about you through your Kyron Community account, or Kyron for Work
          account. If you wish to cancel your account or request that we no
          longer use your information to provide you Services, contact us via
          the information below. We will respond to your request to access or
          delete your information within 30 days. We will retain and use your
          information as necessary to comply with our legal obligations, resolve
          disputes, and enforce our agreements.
          <br />
          <br />
          Kyron <br />
          Attn: Privacy <br />
          Signal Sciences and Artificial Intelligence Private Limited, <br />
          Shri Ganesh Plaza, Kailash Nagar, Narnaul - Rewari Road, <br />
          Narnaul, Haryana, India <br />
          Pin Code: 123001 <br />
          <br />
          Via email: helpdesk@kyron.in with “Privacy” in the subject line
        </p>

        <h3 className="section-privacy__heading2">
          Changes to this Privacy Policy
        </h3>
        <p className="section-privacy__subheading">
          Kyron reserves the right to revise or modify this Privacy Policy. In
          such event, we will post a prominent notice on the Websites prior to
          the change becoming effective.
        </p>
      </div>
    </section>
  );
}

export default PrivacyPage;
