import axios from "axios";
import { baseURL } from "../assets/js/constant";

export const uploadFile = async function (file, data, token) {
  if (!file) return null;
  const ext = `.${file.type.split("/")[1]}`;
  // Sending a request to generate a signed url
  const res = await axios.post(
    `${baseURL}/upload/put-signed-url`,
    { ...data, ext },
    {
      headers: {
        "x-auth-token": token,
        "x-referer-sec-bool":
          "ZW50QHN0dWRlbnQuY29tIiwiX2lkIjoiNjI1ZWRhNmZkNTJmOTgwZjhmMGM2OTZjIiwidHlwZSI6IlMiLCJpYXQiOjE2NjI2MTYyMTcsImV4cCI6MTY2MjY1OTQxNywiYXVkIjoiaHR0cHM6Ly9hcHAua3lyb24uaW4vIiwiaXNzIjoiS1lST04i",
      },
    }
  );

  const img_upload_res = await axios.put(res.data.url, file, {
    headers: {
      "Content-Type": file.type,
    },
  });

  if (img_upload_res.status === 200) {
    return res.data.file_name;
  }
  return null;
};
