import React from "react";
import { useNavigate } from "react-router-dom";
import { Hiring3 } from "../components/hiring";
import Footer from "../components/footer";

import Adobe from "../assets/images/adobe.png";
import Upcoming from "../assets/images/upcoming.png";
import Midas from "../assets/images/midas.png";

// adobe-form

function EventPage() {
  const history = useNavigate();
  // const openAdobeForm = () => {
  //   history("/aca");
  // };
  // const openAdobeForm2 = () => {
  //   history("/emerge");
  // };

  return (
    <section>
      <div className="section-resource">
        <h3 className="section-resource__heading">
          Hackathons, Programming Challenges, <br /> And Coding Competitions
        </h3>
        <p className="section-resource__subheading">
          Source top talents and innovative ideas by conducting best-in-class
          assessments.
        </p>
        <div className="customer-cards">
          {/* <div className="customer-card" onClick={null}>
          <img loading="lazy"
            className="customer-card__image"
            src={Upcoming}
            alt="Adobe career academy"
          />
          <h4 className="customer-card__title">Adobe Career Academy</h4>
          <div className="customer-card__link">Details</div>
        </div>
        <div className="customer-card" onClick={null}>
          <img loading="lazy"
            className="customer-card__image"
            src={Upcoming}
            alt="Adobe emerge"
          />
          <h4 className="customer-card__title">Adobe Emerge</h4>
          <div className="customer-card__link">Details</div>
        </div> */}

          <div className="customer-card" onClick={null}>
            <img
              loading="lazy"
              className="customer-card__image"
              src={Adobe}
              alt="Adobe"
            />
            <h4 className="customer-card__title">Adobe Career Academy</h4>
            <div className="customer-card__link">Private Event</div>
          </div>
          <div className="customer-card" onClick={null}>
            <img
              loading="lazy"
              className="customer-card__image"
              src={Adobe}
              alt="Adobe"
            />
            <h4 className="customer-card__title">Adobe Emerge</h4>
            <div className="customer-card__link">Private Event</div>
          </div>
          <div
            className="customer-card"
            onClick={() => history("/midas-essay")}
          >
            <img
              loading="lazy"
              className="customer-card__image"
              src={Midas}
              alt="Midas"
            />
            <h4 className="customer-card__title">
              Midas Hindi Essay Competition
            </h4>
            <div className="customer-card__link">Details</div>
          </div>
          <div className="customer-card" onClick={null}>
            <img
              loading="lazy"
              className="customer-card__image"
              src={Upcoming}
              alt="Upcoming"
            />
            <h4 className="customer-card__title">Coming soon...</h4>
            {/* <div className="customer-card__link">Details</div> */}
          </div>
        </div>
        <Hiring3 />
      </div>

      <Footer />
    </section>
  );
}

export default EventPage;
