import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Mika from "../assets/images/mika.png";
import Abheet from "../assets/images/abheet.jpeg";
import Aman from "../assets/images/aman.jpg";
import Asad from "../assets/images/asad.jpeg";
import Satya from "../assets/images/satya.jpeg";
import Saurabh from "../assets/images/saurabh.jpeg";

export default class Testimonials extends Component {
  render() {
    return (
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        showIndicators={false}
        interval={5000}
      >
        <div>
          <img src={Mika} alt="Mika" loading="lazy" />
          <div className="myCarousel">
            <h3>Mika Hama</h3>
            <h4>Director, Second Language Testing Institute (SLTI)</h4>
            <p>
              The ASR and scoring models were completed in summer 2021 and our
              AI scored speaking test has been operational since then. Our AI
              speaking test has been used by approximately 1500 test-takers and
              the clients are happy with the accuracy of the test results. The
              system has been quite reliable, and we had only a few minor errors
              with the system. It also supports maintenance work and errors were
              resolved promptly. We together presented the system at a renowned
              language testing conference and our paper will appear in the
              International Journal of Artificial Intelligence in Education in
              2022.
            </p>
          </div>
        </div>
        <div>
          <img src={Aman} alt="Aman" loading="lazy" />
          <div className="myCarousel">
            <h3>Amanullah Asad</h3>
            <h4>Student</h4>
            <p>
              Kyron is a fantastic testing platform that provides an excellent
              exam experience for applicants. The UI is clean and the
              performance is at par with online judges like Codechef and
              Leetcode. The questions appear in an easy to read format and the
              coding questions are solved on the embedded compiler, which is
              very fast and responsive, supporting all the major programming
              languages used in competitive coding.
              <br />
              The candidate verification is very hassle free and secure. Kyron
              tries to be minimally invasive and only accesses the data that is
              mandatory to maintain the integrity of the online test. The
              invigilators are well trained and have a sharp eye for detail.
              Kyron's technical staff and developers are very responsive and
              quick to help out in case of the rare technical glitch.
              <br />I had a marvelous experience with Kyron and would be very
              happy if more recruiters used Kyron for their coding tests.
            </p>
          </div>
        </div>

        <div>
          <img src={Satya} alt="Satya" loading="lazy" />
          <div className="myCarousel">
            <h3>Satya Prakash</h3>
            <h4>Student</h4>
            <p>
              It all started with the coding competition. I participated on the
              Kyron app, held by the MIDAS lab. Kyron proved to be a great
              platform to give exams. The platform was not only intuitive to use
              but also offered a very smooth experience. This has been the best
              platform I’ve ever used so far, for any examination. The user
              experience and overall UI are really amazing. Its easy-to-use UI
              has enabled me to participate as well as give tests on this app.
              I’ll recommend everyone to use this app for online examinations.
            </p>
          </div>
        </div>
        <div>
          <img src={Saurabh} alt="Saurabh" loading="lazy" />
          <div className="myCarousel">
            <h3>Saurabh Sahu</h3>
            <h4>Student</h4>
            <p>
              My first experience with the Kyron was when I participated in a
              coding competition. And it was proved as one of the best platforms
              to conduct tests, as it comes with a compiler. The user experience
              was amazing and the UI was very simple yet interactive and easy to
              use. So far I have found Kyron as the best platform for online
              examinations. The features like live surveillance through live
              cam, open mic and screen sharing make it more reliable for online
              exams to avoid malpractices. Since now, I have given and also
              taken so many examinations on Kyron. I would highly recommend this
              app for online examinations.
            </p>
          </div>
        </div>
        <div>
          <img src={Asad} alt="Asad" loading="lazy" />
          <div className="myCarousel">
            <h3>Asad Nizami</h3>
            <h4>Student</h4>
            <p>
              The test was a delightful experience. Especially the platform on
              which it was conducted, Kyron, was very good. It truly felt like a
              test as  the candidates were monitored properly. Screen monitoring
              as well as camera and microphone monitoring ensured that the test
              was conducted in a fair manner and every candidate got the marks
              they deserved. One can rest assured  that the only thing that
              matters in a competition is their skills and there is no space for
              variables such as unfair means undertaken by some other candidate.
              Kyron is truly an innovative platform for  online proctoring in
              exams. This was my first experience with Kyron, the results were
              impressive and I confident that it would be a success, it
              surpassed my expectations.
            </p>
          </div>
        </div>
        <div>
          <img src={Abheet} alt="Abheet" loading="lazy" />
          <div className="myCarousel">
            <h3>Abheet Kaushal Lodhi</h3>
            <h4>Student</h4>
            <p>
              Kyron proved to be a great platform to take an exam! The platform
              was not only intuitive to use but also offered a very smooth
              experience. This has been the best platform I've used so far, for
              any online examination - and I have taken dozens of such online
              based examinations on a variety of platforms.
            </p>
          </div>
        </div>
      </Carousel>
    );
  }
}
