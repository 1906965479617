import React from "react";
import Footer from "../components/footer";
import Poster1 from "../assets/images/aicts-banner.webp";
import { baseURL } from "../assets/js/constant";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";

function AictsLandingPage() {
  const [aictsPlans, setAictsPlans] = useState([]);

  const getAictsPlan = async () => {
    await axios
      .get(`${baseURL}/plan`)
      .then((response) => {
        setAictsPlans(response.data);
      })
      .catch((err) => {
        toast.error(err.response.data.msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  useEffect(() => {
    getAictsPlan();
  }, []);

  const enrollButton = (id) => {
    window.location.replace(`https://app.kyron.in/signup?aicts_plan=${id}`);
  };

  return (
    <>
      <section className="section-aicts container">
        <h3 className="section-aicts-heading">All India Coding Test Series</h3>
        <img src={Poster1} alt="poster1" className="section-aicts-image" />
        <div className="section-aicts-body">
          <h3 className="section-aicts-subHeading">Test features</h3>
          <ul className="section-aicts-body-list">
            <li className="section-aicts-body-list-item">
              Same exam interface as GATE exam
            </li>
            <li className="section-aicts-body-list-item">
              Detailed Solutions for students reference
            </li>
            <li className="section-aicts-body-list-item">
              Question wise detailed video solutions for student reference
            </li>
            <li className="section-aicts-body-list-item">
              Facility of Ask an Expert ; where you can ask doubts related to
              questions
            </li>
            <li className="section-aicts-body-list-item">
              Overall Analysis report : This report will have
              <ul className="section-aicts-body-sublist">
                <li className="section-aicts-body-sublist-item">
                  Overall Analysis report : This report will have Your Score in
                  that test, along with the number of questions attempted, Time
                  Taken and the Rank secured.
                </li>
                <li className="section-aicts-body-sublist-item">
                  Marks Distribution (positive , negative and unattempted
                  questions)
                </li>
                <li className="section-aicts-body-sublist-item">
                  Time wise Pi Chart ( Skipped, unproductive and productive)
                </li>
              </ul>
            </li>
            <li className="section-aicts-body-list-item">
              Comparison Reports: Comparison reports clearly demonstrate your
              <ul className="section-aicts-body-sublist">
                <li className="section-aicts-body-sublist-item">
                  Your score /Toppers Score
                </li>
                <li className="section-aicts-body-sublist-item">
                  Your Time /Time taken by Topper
                </li>
                <li className="section-aicts-body-sublist-item">
                  Your Accuracy/Topper's Accuracy
                </li>
                <li className="section-aicts-body-sublist-item">
                  Top Ten toppers' score comparison
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="aicts-cards">
          <div className="aicts-card aicts-card--1">
            <h4 className="aicts-card__title"> Exact simulation</h4>

            <ul className="aicts-card__content">
              <li className="aicts-card__content-item">
                Kyron AICTS is the ultimate way to acquaint oneself with the
                actual tests
              </li>
              <li className="aicts-card__content-item">
                Questions are set similarly to that of actual exam
              </li>
              <li className="aicts-card__content-item">
                Helps students guage the exam pattern, syllabus and difficulty
                level
              </li>
            </ul>
          </div>
          <div className="aicts-card aicts-card--2">
            <h4 className="aicts-card__title">Practice</h4>

            <ul className="aicts-card__content">
              <li className="aicts-card__content-item">
                Our system provides with the same pattern and time limit as real
                of the exam
              </li>
              <li className="aicts-card__content-item">
                Level of difficulty is similar to real time coding tests
              </li>
              <li className="aicts-card__content-item">
                Level of difficulty is similar to real time coding tests
              </li>
            </ul>
          </div>
          <div className="aicts-card aicts-card--3">
            <h4 className="aicts-card__title">Real time situations</h4>

            <ul className="aicts-card__content">
              <li className="aicts-card__content-item">
                Our system finds the rightway to simulate the real-life exam
                hall situation
              </li>
              <li className="aicts-card__content-item">
                A great way to frame the mindset for online coding tests
              </li>
              <li className="aicts-card__content-item">
                Helps to think out out-of-the box and come across situations
                that might not be appparent otherwise
              </li>
            </ul>
          </div>
        </div>
        <div className="section-aicts-table">
          <h3 className="section-aicts-subHeading">Fee structure</h3>
          {aictsPlans.length ? (
            <table className="section-aicts-table-structure">
              <thead>
                <tr className="section-aicts-table-heading">
                  <th className="section-aicts-table-heading-sNo">S. No.</th>
                  <th className="section-aicts-table-heading-package">
                    Package
                  </th>
                  <th className="section-aicts-table-heading-commencingDates">
                    Commencing Dates
                  </th>
                  <th className="section-aicts-table-heading-fee">
                    Fee (inclusive of GST)
                  </th>
                  <th className="section-aicts-table-heading-enroll">Enroll</th>
                </tr>
              </thead>
              <tbody>
                {aictsPlans.map((plan, key) => (
                  <tr key={`aictsplan-${key}`}>
                    <td className="section-aicts-table-rowData">{key + 1}.</td>
                    <td className="section-aicts-table-rowData">
                      {plan.title}
                    </td>
                    <td className="section-aicts-table-rowData">
                      {plan.description}
                    </td>
                    <td className="section-aicts-table-rowData-fee">
                      {/* <span className="section-aicts-table-span">Rs. 2000 + GST</span> */}
                      Rs. {plan.amount} /-only
                    </td>
                    <td className="section-aicts-table-rowData">
                      <button
                        className="primary-button"
                        onClick={() => enrollButton(plan._id)}
                      >
                        Enroll
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>Something went wrong</p>
          )}
        </div>
        <h4 style={{paddingTop: "5px",color: "orange", fontWeight: "normal"}}>
          <b> No Refunds.</b> All sales are final, and the Company does not
          offer any money-back guarantees. You recognize and agree that you
          shall not be entitled to a refund for any purchase under any
          circumstances.*
        </h4>
      </section>
      <div className="section-aicts-footer">
        <Footer />
      </div>
    </>
  );
}

export default AictsLandingPage;
