import React from "react";
import { NavLink } from "react-router-dom";
function Hiring1() {
  return (
    <div className="hiring-section">
      <h1 className="hiring-section-heading">Ready to Start?</h1>
      <div className="hiring-section-buttons">
        {/* <a className="primary-button-shadow" href="/#">
          Free Trial
        </a> */}
        <NavLink className="btn-secondary" to="/recruit">
          Start Hiring
        </NavLink>
        <NavLink className="btn-secondary" to="/academy">
         Start Learning
        </NavLink>
      </div>
    </div>
  );
}
function Hiring2() {
  return (
    <div className="hiring-section">
      <h1 className="hiring-section-heading">
        Are you ready to revolutionize how you hire tech talent?
      </h1>
      <div className="hiring-section-buttons">
        <NavLink className="primary-button-shadow" to="/contact">
          Schedule Demo
        </NavLink>
      </div>
    </div>
  );
}
function Hiring3() {
  return (
    <div className="hiring-section">
      <h1 className="hiring-section-heading">
        Are you ready to revolutionize how you hire tech talent?
      </h1>
      <div className="hiring-section-buttons">
        <NavLink className="primary-button-shadow" to="/contact">
          Try for free
        </NavLink>
      </div>
    </div>
  );
}
function FillForm() {
  return (
    <div className="hiring-section">
      <h1 className="hiring-section-heading">
        Fill this Form for ?
      </h1>
      <div className="hiring-section-buttons">
        <NavLink className="primary-button-shadow" to="/contact">
          Try for free
        </NavLink>
      </div>
    </div>
  );
}

export { Hiring1, Hiring2, Hiring3, FillForm };
