// Name validation
async function validateName(value, instance) {
  if (!value) {
    return "A name is required";
  }
  if (!onlyText(value)) {
    return "Number is not allow";
  }
  return instance.debounce(async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    // All names are valid, so return a false error
    return false;
  }, 100);
}

function onlyText(key) {
  const re = /^[A-Za-z !@#$%^&*)(]+$/;

  if (re.test(key)) {
    return true;
  } else {
    return false;
  }
}

// General Validation
async function validateGeneral(value, instance, text) {
  if (!value) {
    return text;
  }

  return instance.debounce(async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    // All names are valid, so return a false error
    return false;
  }, 100);
}

// General number validation
async function validateNumberGeneral(value, instance, text1, text2) {
  if (!value) {
    return text1;
  }
  if (parseInt(value) < 0) {
    return text2;
  }
  return instance.debounce(async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    // All names are valid, so return a false error
    return false;
  }, 100);
}
// General number validation
async function validateYear(value, instance, text1, text2) {
  if (!value) {
    return text1;
  }
  if (parseInt(value) < 0 || value.length < 4 || value.length >4) {
    return text2;
  }
  return instance.debounce(async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    // All names are valid, so return a false error
    return false;
  }, 100);
}
// JEee rank number validation
async function validateJeeRank(value, instance, text1) {
  if (value < 0) {
    return text1;
  }
  return instance.debounce(async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    // All names are valid, so return a false error
    return false;
  }, 100);
}

// Email validation
async function validEmail(value, instance) {
  if (!value) {
    return "Email is required";
  }
  if (!validateEmail(value)) {
    return "Please enter a valid email addresss";
  }

  return instance.debounce(async () => {
    console.log("checking email");
    await new Promise((resolve) => setTimeout(resolve, 100));
    // All names are valid, so return a false error
    return false;
  }, 100);
}

// Mobile validation
async function validMobile(value, instance) {
  if (!value) {
    return "Mobile No is required";
  }
  if (value.length < 10 || value.length > 10) {
    return "Please enter a valid mobile no";
  }
  if (parseInt(value) < 0) {
    return "Please enter a valid mobile no";
  }

  return instance.debounce(async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    // All names are valid, so return a false error
    return false;
  }, 100);
}

// CGPA validation
async function validCgpa(value, instance) {
  if (!value) {
    return "cgpa is required";
  }
  if (value > 10) {
    return "Please enter a valid cgpa";
  }
  if (parseInt(value) < 0) {
    return "Please enter a positive number";
  }

  return instance.debounce(async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    // All names are valid, so return a false error
    return false;
  }, 100);
}
// Percentage validation
async function validPercentage(value, instance) {
  if (!value) {
    return "Answer is required";
  }
  if (value > 100) {
    return "Please enter a valid answer";
  }
  if (value < 0) {
    return "Please enter a positive number";
  }

  return instance.debounce(async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    // All names are valid, so return a false error
    return false;
  }, 100);
}

// CGPA validation
async function validGeneralWithWordCount(value, instance, text1) {
  if (!value) {
    return text1;
  }
  if (value.split(" ").length > 300) {
    return "Max word limit reached";
  }

  return instance.debounce(async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    // All names are valid, so return a false error
    return false;
  }, 100);
}

// Validate email subfunciton
function validateEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export {
  validateName,
  validEmail,
  validMobile,
  validateGeneral,
  validateNumberGeneral,
  validCgpa,
  validGeneralWithWordCount,
  validPercentage,
  validateJeeRank,
  validateYear
};
