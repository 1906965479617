import { forwardRef } from 'react';
import Spinner from '../Spinner/Spinner';

import styles from './Button.module.scss';

const Button = forwardRef(
  (
    {
      children,
      loading,
      loadingText,
      className,
      spinnerClassName = '',
      isText = false,
      ...rest
    },
    ref
  ) => {
    return (
      <>
        {/* <Spinner/> */}
        <button
          ref={ref}
          {...rest}
          className={`${isText && styles.textButton} ${styles.button} ${className}`}
        >
          {loading && loadingText ? (
            <div style={{ display: 'flex', gap: '1em' }}>
              <Spinner className={spinnerClassName} /> <p>{loadingText}</p>
            </div>
          ) : loading ? (
            <Spinner className={spinnerClassName} />
          ) : (
            children
          )}
        </button>
      </>
    );
  }
);

export default Button;
