import React from "react";
import { Hiring1 } from "../components/hiring";
import Footer from "../components/footer";
import Platform11 from "../assets/images/platform11.png";
import Platform2 from "../assets/images/platform2.png";
function Science() {
  return (
    <section>
      <div className="section-science">
        <h3 className="section-science__heading">Recruitment Finesse</h3>
        <p className="section-science__subheading">
          The science of recruitment lies within the intricate art of aligning
          skills with the candidate’s intrinsic skill sets, potential and
          behavioral capabilities. <br /> Sounds complex! Don’t worry, we are
          here to help. Kyron niche and well refined system trained by the top
          of the class researchers from Human Computer Interaction department -
          screen the skills out from the complex job descriptions and find the
          perfect candidate for your organization. And since, we are scientists,
          our system boasts of being:
        </p>

        <div className="science-cards">
          <div className="science-card science-card--1">
            <h4 className="science-card__title">Relevant</h4>
            <h4 className="science-card__subtitle">Is the test job-related?</h4>
            <ul className="science-card__content">
              <li className="science-card__content-item">
                Our system finds the right skills to be used to screen right
                talent
              </li>
              <li className="science-card__content-item">
                Right talent is assessed through the right set of assessments
              </li>
              <li className="science-card__content-item">
                Right assessments perfectly captures the skill performance
              </li>
            </ul>
          </div>
          <div className="science-card science-card--2">
            <h4 className="science-card__title">Consistent</h4>
            <h4 className="science-card__subtitle">
              Is the test reliable?
            </h4>{" "}
            <ul className="science-card__content">
              <li className="science-card__content-item">
                Our assessments accurately measures the skills they intend to
                measure
              </li>
              <li className="science-card__content-item">
                Assessments quantitatively measures the skills and competencies
              </li>
              <li className="science-card__content-item">
                Right assessments perfectly captures the knowledge areas and
                skills
              </li>
            </ul>
          </div>
          <div className="science-card science-card--3">
            <h4 className="science-card__title">Unbiased</h4>
            <h4 className="science-card__subtitle">Is the test fair?</h4>{" "}
            <ul className="science-card__content">
              <li className="science-card__content-item">
                Assessments are curated based on scientific principles and
                validated
              </li>
              <li className="science-card__content-item">
                Assessments are regularly revised and recurated based on
                feedback input
              </li>
              <li className="science-card__content-item">
                Right assessments perfectly captures the knowledge areas and
                skills
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="dark-section">
        <div className="dark-section-content">
          <div className="dark-section-1">
            <div className="dark-section-1-heading">
              AI + Human Proctored Tests
            </div>
            <div className="dark-section-1-subheading">
              We ensure that each test’s integrity is maintained and we are
              proud to present industry standard proctoring platform
            </div>
            <ul className="dark-section-1-list">
              <li className="dark-section-1-item">
                Each candidate’s identity is thoroughly verified by our AI
                system
              </li>
              <li className="dark-section-1-item">
                Our advanced AI alerts against any flag
              </li>
              <li className="dark-section-1-item">
                Candidates are under the continuous vigil of a dedicated human
                proctor{" "}
              </li>
            </ul>

            {/* <div className="dark-section-1-button">
              <a className="primary-button-main" href="/#">
                Free Trial
              </a>
            </div> */}
          </div>
          <div className="dark-section-2">
            <img
              loading="lazy"
              className="dark-section-2-image"
              src={Platform11}
              alt="platform 1"
            />
          </div>
        </div>
      </div>
      <div className="light-section">
        <div className="light-section-content">
          <div className="light-section-2">
            <img
              loading="lazy"
              className="light-section-2-image"
              src={Platform2}
              alt="platform 2"
            />
          </div>
          <div className="light-section-1">
            <div className="light-section-1-heading">
              Modular Test Creation & Assessment Methodology
            </div>
            <div className="light-section-1-subheading">
              Our well researched methodology creates unique tests for every job
              profile
            </div>
            <ul className="light-section-1-list">
              <li className="light-section-1-item">
                Each job role is seen through the perspective of detailed Skill
                Inventory
              </li>
              <li className="light-section-1-item">
                Test is created using the modular system to assess each Skill
              </li>
              <li className="light-section-1-item">
                Scoring methodology is defined & normalized using the industry
                data and organization data{" "}
              </li>
            </ul>

            {/* <div className="light-section-1-button">
              <a className="primary-button-main" href="/#">
                Free Trial
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <Hiring1 />
      <Footer />
    </section>
  );
}

export default Science;
