import React from "react";
import Navbar from "./components/navBar";
import Customers from "./pages/customers";
import HomePage from "./pages/home";
import Science from "./pages/science";
import ResourcePage from "./pages/resources";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { BrowserRouter, Route, useLocation, Routes } from "react-router-dom";
// import { TransitionGroup, CSSTransition } from "react-transition-group";

import CompanyPage from "./pages/compnay";
import ScrollToTop from "./components/scrollToTop";
import ContactPage from "./pages/contact";
import ProductPage from "./pages/products";
import FaqPage from "./pages/faq";
import EventPage from "./pages/events";
import AdobeFormPage from "./pages/adobe-form";
import AdobeFormPage2 from "./pages/adobe-form2";
import LearnPage from "./pages/learn";
import JobPage from "./pages/job";
import AdobeSheCodes from "./pages/adobe-shecodes";
import AdobeLandingPage from "./pages/AdobeLandingPage/AdobeLandingPage";
import MidasEssay from "./pages/MidasEssay/MidasEssay";
import AictsLandingPage from "./pages/aicts";
import Error from "./pages/Error";
import PrivacyPage from "./pages/Privacy";
import TermsOfService from "./pages/TermsOfService";
import ReturnPolicy from "./pages/ReturnPolicy";
import LayoffCandidatesForm from "./pages/layoff-candidates";

function App() {
  // const location = useLocation();
  return (
    <>
      <BrowserRouter>
        <Navbar />

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* <TransitionGroup>
          <CSSTransition key={location.key} classNames="slide" timeout={2000}> */}
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/jobs" element={<JobPage />} />
          <Route path="/recruit" element={<ProductPage />} />
          <Route path="/academy" element={<LearnPage />} />
          {/* Redirect to recruit */}
          <Route path="/product" element={<Navigate to="/recruit" replace />} />
          <Route path="science" element={<Science />} />
          <Route path="privacy" element={<PrivacyPage />} />
          <Route path="terms-of-service" element={<TermsOfService />} />
          <Route path="return-policy" element={<ReturnPolicy />} />
          <Route path="customers" element={<Customers />} />
          <Route path="resources" element={<ResourcePage />} />
          <Route path="faq" element={<FaqPage />} />
          <Route path="company" element={<CompanyPage />} />
          <Route path="events" element={<EventPage />} />
          <Route path="aca" element={<AdobeFormPage />} />
          <Route path="emerge" element={<AdobeFormPage2 />} />
          <Route path="shecodes" element={<AdobeSheCodes />} />
          <Route path="adobe" element={<AdobeLandingPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="midas-essay" element={<MidasEssay />} />
          <Route path="aicts" element={<AictsLandingPage />} />
          <Route path="candidates-info" element={<LayoffCandidatesForm />} />
          <Route path="*" element={<Error />} />
        </Routes>
        {/* </CSSTransition>
        </TransitionGroup> */}
      </BrowserRouter>
    </>
  );
}

export default App;
