import React from "react";
import axios from "axios";

import { toast } from "react-toastify";
import { useForm, useField, splitFormProps } from "react-form";
import { baseURL } from "../assets/js/constant";
import AsyncSelect from "react-select/async";

import { useNavigate } from "react-router-dom";
import {
  validateName,
  validEmail,
  validMobile,
  validateGeneral,
  validateYear,
  validCgpa,
  validPercentage,
  validateJeeRank,
} from "../helper/validation";
import { useState } from "react";
import Modal from "../components/Modal/Modal";
import Label from "../components/Label/Label";
import Button from "../components/Button/Button";
import { uploadFile } from "../helper/upload";
import { useEffect } from "react";

let token = "";

async function sendToServer(values, history) {
  console.log(values, history);
  await axios
    .post(`${baseURL}/form/adobe-shecodes`, values)
    .then((response) => {
      toast.success(response.data.msg, {
        position: toast.POSITION.TOP_CENTER,
      });
      history("/", { replace: true });
    })
    .catch((err) => {
      toast.error(err.response.data, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
}

function AdobeSheCodes() {
  const history = useNavigate();

  const fetchToken = async () => {
    await axios
      .post(`${baseURL}/auth/temp`)
      .then((response) => {
        token = response?.data.token;
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  useEffect(() => {
    fetchToken();
  }, []);

  // Use the useForm hook to create a form instance
  const {
    Form,
    meta: { isSubmitting, canSubmit },
  } = useForm({
    onSubmit: async (values, instance) => {
      // onSubmit (and everything else in React Form)
      // has async support out-of-the-box
      await sendToServer(values, history);
      console.log("Huzzah!");
    },
    debugForm: false,
  });

  return (
    <div>
      <section className="section-adobe-form" style={{ height: "100%" }}>
        <div className="section-adobe-form-main">
          <div className="section-adobe-form-main-head">
            <div className="section-adobe-form-main--heading">
              Adobe She Codes - Registration link
            </div>
            <div className="section-adobe-form-main--subheading">
              Dear Candidate, <br />
              Thank you for expressing interest in Adobe!
              <br />
              Please fill the below form to register yourself for the program.
              {/* Registrations has been closed. */}
              <br />
              Thanks
            </div>
            <div className="section-adobe-form-foot--text ">
              In case you face any issues, you can reach out to us at &nbsp;
              <a
                href="mailto: helpdesk@kyron.in"
                className="section-adobe-form-link"
              >
                helpdesk@kyron.in
              </a>{" "}
              or &nbsp;
              <a href="tel:+91-11-26907495" className="section-adobe-form-link">
                +91-11-26907495
              </a>
            </div>
          </div>
          <Form className="section-adobe-form-main-body form">
            <p className="section-adobe-form-main-body--subheading">
              <span>*</span> Required
            </p>
            <ProfileField />
            <NameField />
            <MobileField />
            <EmailField />
            <GenderField
              field="gender"
              options={[
                "Male",
                "Female",
                "Transgender",
                "Non-binary/non-conforming",
                "Other",
                "Don't prefer to say",
              ]}
              validate={(value) => (!value ? "This is required!" : false)}
            />
            <CollegeNameField
              field="college"
              validate={(value) => (!value ? "This is required!" : false)}
            />

            <GraduationYearField />
            <CurrentProgramField />
            <SpecializationField />
            <CgpaField />
            <ResumeField />
            {!isSubmitting ? (
              <button
                className="form-btn u-margin-top-small"
                type="submit"
                disabled={!canSubmit}
              >
                Submit
              </button>
            ) : (
              <div className="loader"></div>
            )}
          </Form>
          <div className="section-adobe-form-foot">
            <div className="section-adobe-form-foot--text u-margin-bottom-small">
              This content is created by the owner of the form. The data you
              submit will be sent to the form owner. Kyron is not responsible
              for the privacy or security practices of its customers, including
              those of this form owner. Never give out your password.
            </div>
            <div className="section-adobe-form-foot--text ">
              If you are facing any issues please reach out to us at &nbsp;
              <a
                href="mailto: helpdesk@kyron.in"
                className="section-adobe-form-link"
              >
                helpdesk@kyron.in
              </a>{" "}
              or &nbsp;
              <a href="tel:+91-11-26907495" className="section-adobe-form-link">
                +91-11-26907495
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

function ProfileField() {
  const {
    meta: { error, isTouched, isValidating },
    value = null,
    setValue,
  } = useField("profile_picture", {
    validate: (val, inst) => validateGeneral(val, inst, "Image is required"),
  });

  const handleFileUpload = async (e) => {
    const fileName = await uploadFile(
      e.target.files[0],
      {
        id: "adobe-shecodes-aug-2022",
        type: "REGISTRATIONS",
      },
      token
    );
    setValue(fileName);
  };

  return (
    <div className="question-box">
      <label htmlFor="name" className="question-box__label">
        1. Your recent passport size photo for test <span>*</span>
      </label>
      <input
        type="file"
        accept="image/*"
        onChange={(e) => {
          handleFileUpload(e);
        }}
        className="question-box__input"
      />

      <label className="question-box__error">
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}
function NameField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("name", {
    validate: validateName,
  });

  return (
    <div className="question-box">
      <label htmlFor="name" className="question-box__label">
        2. Your name <span>*</span>
      </label>
      <input
        {...getInputProps()}
        type="text"
        placeholder="Enter your name"
        id="name"
        // required
        className="question-box__input"
      />

      <label className="question-box__error">
        {" "}
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

function EmailField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("email", {
    validate: validEmail,
  });

  return (
    <div className="question-box">
      <label htmlFor="email" className="question-box__label">
        4. Your email address <small>(Test assigned at this id)</small>{" "}
        <span>*</span>
      </label>
      <input
        id="email"
        type="email"
        placeholder="Enter your email"
        // required
        className="question-box__input"
        {...getInputProps()}
      />
      <label className="question-box__error">
        {" "}
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

function MobileField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("mobile", {
    validate: validMobile,
  });

  return (
    <div className="question-box">
      <label htmlFor="mobile" className="question-box__label">
        3. Your mobile phone number <span>*</span>
      </label>
      <div className="input-box">
        <span>+91</span>
        <input
          id="mobile"
          type="number"
          min="0"
          placeholder="Enter your mobile number"
          // required
          className="input-box__input"
          {...getInputProps()}
        />
      </div>

      <label className="question-box__error">
        {" "}
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

function GenderField(props) {
  const [field, fieldOptions, { options, ...rest }] = splitFormProps(props);

  const {
    value = "",
    setValue,
    meta: { error, isTouched },
  } = useField(field, fieldOptions);

  const handleSelectChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="question-box">
      <label htmlFor="gender" className="question-box__label">
        5. Gender <span>*</span>
      </label>
      <select
        {...rest}
        value={value}
        onChange={handleSelectChange}
        className="question-box__input"
      >
        <option disabled value="">
          Select your gender
        </option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <label className="question-box__error">
        {" "}
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

function CollegeNameField(props) {
  const [field, fieldOptions, { options, ...rest }] = splitFormProps(props);

  const {
    value = "",
    setValue,
    meta: { error, isTouched },
  } = useField(field, fieldOptions);
  const [localValue, setLocalValue] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [input, setInput] = useState({
    college_name: "",
  });

  const handleSelectChange = (e) => {
    setLocalValue(e);
    setValue(e.value);
  };

  const promiseOptions = async (inputValue) => {
    return await axios
      .get(`${baseURL}/college?name=${inputValue}`)
      .then((response) => {
        const arrayMap = response.data.slice(0, 20).map((data) => {
          return { value: data.name, label: data.name };
        });
        return arrayMap;
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const debounce = (f, interval) => {
    let timer = null;

    return (...args) => {
      clearTimeout(timer);
      return new Promise((resolve) => {
        timer = setTimeout(() => resolve(f(...args)), interval);
      });
    };
  };
  const handleSave = async () => {
    setIsLoading(true);
    await axios
      .post(
        `${baseURL}/college`,
        { name: input.college_name },
        {
          headers: {
            "x-auth-token": token,
            "x-referer-sec-bool":
              "ZW50QHN0dWRlbnQuY29tIiwiX2lkIjoiNjI1ZWRhNmZkNTJmOTgwZjhmMGM2OTZjIiwidHlwZSI6IlMiLCJpYXQiOjE2NjI2MTYyMTcsImV4cCI6MTY2MjY1OTQxNywiYXVkIjoiaHR0cHM6Ly9hcHAua3lyb24uaW4vIiwiaXNzIjoiS1lST04i",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        setIsLoading(false);
        setIsModal(false);
        setInput({
          college_name: "",
        });
        toast.success("Your college successfully added", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setIsModal(false);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const defaultText = "Please enter keyword to search your college";

  return (
    <div className="question-box">
      <label htmlFor="college" className="question-box__label">
        6. Your college name <small>(search by full name)</small> <span>*</span>
      </label>

      <AsyncSelect
        loadOptions={debounce(promiseOptions, 500)}
        value={localValue}
        onChange={handleSelectChange}
        placeholder="Select your college"
        className="question-box__searchInput"
        // components={{ Menu: CustomMenu }}
        noOptionsMessage={({ inputValue }) =>
          !inputValue ? defaultText : "No results found"
        }
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <label className="question-box__error">
          {isTouched && error ? <em>{error}</em> : null}
        </label>
        <button onClick={() => setIsModal(true)} className="btn">
          Add your college
        </button>
      </div>

      <Modal
        isModal={isModal}
        onClose={() => setIsModal(false)}
        showCloseButton
        className="new-college-modal"
      >
        <Label className="u-margin-bottom-medium">Add your college</Label>
        <label htmlFor="college_name" className="question-box__label">
          Your college name <span>*</span>
          <br />
          <small>Make sure your college is not available in our list</small>
        </label>
        <input
          id="college_name"
          type="college_name"
          value={input.college_name}
          onChange={(e) =>
            setInput((prevState) => {
              return {
                ...prevState,
                college_name: e.target.value,
              };
            })
          }
          placeholder="Enter your text here"
          className="question-box__input"
        />
        <Button
          onClick={() => handleSave()}
          className="new-college-modal-button"
          loading={isLoading}
        >
          Save
        </Button>
      </Modal>
    </div>
  );
}

function GraduationYearField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("graduation_year", {
    validate: (val, inst) =>
      validateYear(
        val,
        inst,
        "Answer is required",
        "Please enter a correct year"
      ),
  });

  return (
    <div className="question-box">
      <label htmlFor="graduation" className="question-box__label">
        7. Year of graduation <span>*</span>
      </label>
      <input
        id="graduation"
        type="number"
        placeholder="Enter your answer"
        min="0"
        // required
        className="question-box__input"
        {...getInputProps()}
      />

      <label className="question-box__error">
        {" "}
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

function CurrentProgramField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("current_course", {
    validate: (val, inst) => validateGeneral(val, inst, "Answer is required"),
  });

  return (
    <div className="question-box">
      <label htmlFor="program" className="question-box__label">
        8. Your current program/ course/ degree <span>*</span>
      </label>
      <input
        id="program"
        type="text"
        placeholder="Enter your answer"
        // required
        className="question-box__input"
        {...getInputProps()}
      />

      <label className="question-box__error">
        {" "}
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

function SpecializationField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("specialization", {
    validate: (val, inst) =>
      validateGeneral(val, inst, "Specialization is required"),
  });

  return (
    <div className="question-box">
      <label htmlFor="spec" className="question-box__label">
        9. Specialization <span>*</span>
      </label>
      <input
        id="spec"
        type="text"
        placeholder="Enter your specialization"
        // required
        className="question-box__input"
        {...getInputProps()}
      />

      <label className="question-box__error">
        {" "}
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

function CgpaField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("cgpa", {
    validate: validCgpa,
  });

  return (
    <div className="question-box">
      <label htmlFor="cgpa" className="question-box__label">
        10. CGPA Score until the last semester held <span>*</span>
      </label>
      <input
        id="cgpa"
        type="number"
        placeholder="Enter your cgpa on the scale of 10"
        // required
        className="question-box__input"
        {...getInputProps()}
      />

      <label className="question-box__error">
        {" "}
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

function ResumeField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("resume_link", {
    validate: (val, inst) =>
      validateGeneral(val, inst, "Resume link is required"),
  });

  return (
    <div className="question-box">
      <label htmlFor="resume" className="question-box__label">
        11. Link to resume <span>*</span>
      </label>
      <input
        id="resume"
        type="text"
        placeholder="Enter your answer"
        // required
        className="question-box__input"
        {...getInputProps()}
      />

      <label className="question-box__error">
        {" "}
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

export default AdobeSheCodes;
