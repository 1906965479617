import React from "react";
import Globe from "react-globe.gl";

function GlobeComponents() {
  const N = 40;
  const arcsData = [...Array(N).keys()].map(() => ({
    startLat: (Math.random() - 0.5) * 180,
    startLng: (Math.random() - 0.5) * 360,
    endLat: (Math.random() - 0.5) * 180,
    endLng: (Math.random() - 0.5) * 360,
    color: [
      ["red", "white", "blue", "green"][Math.round(Math.random() * 3)],
      ["red", "white", "blue", "green"][Math.round(Math.random() * 3)],
    ],
  }));
  return (
    <Globe
      backgroundColor="#fff"
      width={600}
      height={600}
      showAtmosphere={true}
      globeImageUrl="//unpkg.com/three-globe/example/img/earth-day.jpg"
      arcsData={arcsData}
      arcsTransitionDuration={2000}
      arcColor={"color"}
      arcDashLength={() => Math.random()}
      arcDashGap={() => Math.random()}
      arcDashAnimateTime={() => Math.random() * 4000 + 500}
    />
  );
}

export default GlobeComponents;
