import React from "react";
import Logo from "../assets/vlogo2.png";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-box">
        <img loading="lazy" src={Logo} alt="logo" className="footer-logo" />
        <div className="footer-links">
          {/* <div className="footer__icons">
            <a
              href="/#"
              target="_blank"
              rel="noreferrer"
              className="footer__icons-link"
            >
              <img
                loading="lazy"
                className="footer__icons-icon"
                src="https://img.icons8.com/color/48/000000/linkedin.png"
                alt="linkedin"
              />
            </a>
            <a
              href="/#"
              target="_blank"
              rel="noreferrer"
              className="footer__icons-link"
            >
              <img
                loading="lazy"
                className="footer__icons-icon"
                src="https://img.icons8.com/color/48/000000/github--v1.png"
                alt="Github"
              />
            </a>
            <a
              href="/#"
              target="_blank"
              rel="noreferrer"
              className="footer__icons-link"
            >
              <img
                loading="lazy"
                className="footer__icons-icon"
                src="https://img.icons8.com/color/48/000000/stackoverflow.png"
                alt="Stackoverflow"
              />
            </a>
            <a href="/#" className="footer__icons-link">
              <img
                loading="lazy"
                className="footer__icons-icon"
                src="https://img.icons8.com/color/48/000000/apple-phone.png"
                alt="Phone"
              />
            </a>
          </div> */}
          <ul className="navbar-link">
            <li>
              <NavLink
                to="/recruit"
                className={({ isActive }) =>
                  isActive
                    ? "active-link navbar-link-item "
                    : "navbar-link-item "
                }
              >
                Recruit
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/academy"
                className={({ isActive }) =>
                  isActive
                    ? "active-link navbar-link-item "
                    : "navbar-link-item "
                }
              >
                Academy
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/science"
                className={({ isActive }) =>
                  isActive
                    ? "active-link navbar-link-item "
                    : "navbar-link-item "
                }
              >
                Science
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/customers"
                className={({ isActive }) =>
                  isActive
                    ? "active-link navbar-link-item "
                    : "navbar-link-item "
                }
              >
                Customers
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/company"
                className={({ isActive }) =>
                  isActive
                    ? "active-link navbar-link-item "
                    : "navbar-link-item "
                }
              >
                Company
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/events"
                className={({ isActive }) =>
                  isActive
                    ? "active-link navbar-link-item "
                    : "navbar-link-item "
                }
              >
                Events
              </NavLink>
            </li>
          </ul>
          <ul className="navbar-link">
            
            <li>
              <NavLink
                to="/privacy"
                className={({ isActive }) =>
                  isActive
                    ? "active-link navbar-link-item "
                    : "navbar-link-item "
                }
              >
                Privacy Policy
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/return-policy"
                className={({ isActive }) =>
                  isActive
                    ? "active-link navbar-link-item "
                    : "navbar-link-item "
                }
              >
                Return Policy
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/terms-of-service"
                className={({ isActive }) =>
                  isActive
                    ? "active-link navbar-link-item "
                    : "navbar-link-item "
                }
              >
                Terms of Service
              </NavLink>
            </li>
           
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
