import React from "react";
import Footer from "../components/footer";

import Iitd from "../assets/images/iitd.png";
import Lab from "../assets/images/lab.jpg";
import Midas from "../assets/images/midas.png";
import Incubation from "../assets/images/incubation.png";
import Login from "../assets/images/login.png";
import Rajiv from "../assets/images/rajiv2.png";

function CompanyPage() {
  const openIIITD = () => {
    window.open("https://iiitd.ac.in/");
  };
  const openMidas = () => {
    window.open("http://midas.iiitd.edu.in/");
  };
  const openIITDInovation = () => {
    window.open("https://iiitdic.in/");
  };

  return (
    <div>
      <section className="section-company">
        <h3 className="section-company__heading">About Us</h3>
        <p className="company__1-content-subheading u-margin-top-xssmall">
          Kyron is a technology hiring and skilling platform that is the
          standard for assessing developer skills for many top companies around
          the world. By enabling tech recruiters and hiring managers to
          objectively evaluate talent at every stage of the recruiting process,
          Kyron helps companies hire skilled developers and innovate faster.{" "}
        </p>
        <div className="company__0">
          <div className="company__0-imagebox">
            <img
              loading="lazy"
              className="company__0-image"
              src={Rajiv}
              alt="Rajiv"
            />
          </div>
          <div className="company__0-content">
            {/* <h3 className="company__0-content-heading">Dr Rajiv Ratn Shah</h3> */}
            {/* <p className="company__0-content-heading2">
              PhD in Computer Science...
            </p> */}
            {/* <p className="company__0-content-subheading">sdfsf</p> */}

            <p className="company__0-content-subheading">
              <i>
                {" "}
                My academic tenure at IIIT Delhi, NUS Singapore & mentoring
                experience with students from Harvard University, CMU, MIT, IIT
                have illustrated the widening gap between the theoretical know
                how and practical application. As advisor and consultant to
                major companies as Adobe, Microsoft and Google further
                strengthened my notions around the widening gap between academia
                and industry. With help of behavioral sciences lab & HR experts
                from IIM A, Harvard & XLRI, we have created an ecosystem to make
                students job ready, access based on competency mapping and get
                them to the perfect job through industry collaboration. And all
                of this, through trusted, integrated, validated AI system and
                thoroughly researched learning psychology.
              </i>
            </p>
            <p className="company__0-content-subheading">
              <b> - Dr Rajiv Ratn Shah, </b> <br /> PhD in Computer Science,{" "}
              <a href="https://www.nus.edu.sg/">
                National University of Singapore
              </a>{" "}
              <br /> Head, Department of{" "}
              <a href="https://hcd.iiitd.ac.in/">Human-Centered Design</a>{" "}
              (HCD), <a href="https://www.iiitd.ac.in/">IIIT-D</a> <br />{" "}
              Assistant Professor, Department of CSE & HCD,{" "}
              <a href="https://www.iiitd.ac.in/">IIIT-D</a> <br />
              Director,{" "}
              <a href="http://midas.iiitd.edu.in/">
                Multimodal Digital Media Analysis Lab (MIDAS)
              </a>
            </p>
            <div className="company__0-content__icons">
              <a
                href="https://www.linkedin.com/in/rajivratn/"
                target="_blank"
                rel="noreferrer"
                className="company__0-content__icons-link"
              >
                <img
                  loading="lazy"
                  className="company__0-content__icons-icon"
                  src="https://img.icons8.com/color/48/000000/linkedin.png"
                  alt="linkedin"
                />
              </a>
              <a
                href="https://scholar.google.com.sg/citations?user=WAChZv4AAAAJ&hl=en"
                target="_blank"
                rel="noreferrer"
                className="company__0-content__icons-link"
              >
                <img
                  loading="lazy"
                  className="company__0-content__icons-icon"
                  src="https://img.icons8.com/color/344/google-scholar--v3.png"
                  alt="Google scholar"
                />
              </a>
              <a
                href="https://www.iiitd.ac.in/rajivratn"
                target="_blank"
                rel="noreferrer"
                className="company__0-content__icons-link"
              >
                <img
                  loading="lazy"
                  className="company__0-content__icons-icon"
                  src="https://img.icons8.com/stickers/344/domain.png"
                  alt="Link"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="company__1">
          <div className="company__1-content">
            <h3 className="company__1-content-heading">Why Kyron?</h3>
            <p className="company__1-content-heading2">
              We provide industry-leading technology, personalized service,
              industry expertise, and extensive infrastructure.
            </p>
            <p className="company__1-content-subheading">
              This unique combination allows us to help you exceed your goals,
              grow your testing program, and move your organization and
              test-takers forward in a way that other testing providers simply
              can’t. Having a true test development and delivery partner – not
              just a vendor – is crucial. Our dedicated team will walk you
              through the whole process, every step of the way. The best part?
              You get to choose how involved you want to be. While our
              concierge-level solutions make it easy for you to work
              independently, we can also provide expert guidance whenever you
              need it.
            </p>
          </div>
          <div className="company__1-imagebox">
            <img
              loading="lazy"
              className="company__1-image"
              src={Lab}
              alt="Lab"
            />
          </div>
        </div>
        <div className="company__2 u-margin-top-huge">
          <div className="company__2-imagebox">
            <img
              loading="lazy"
              className="company__2-image"
              src={Login}
              alt="Login"
            />
          </div>
          <div className="company__2-content">
            <h3 className="company__2-content-heading">
              Secure, Industry-Leading Technology
            </h3>
            <p className="company__2-content-subheading">
              We’ve been offering customizable testing solutions since 2019.
              Because we were founded on a culture of continuous improvement, we
              use every challenge we face as a company-wide teachable moment. We
              listen to client and test-taker concerns so we can refine our
              testing platforms and tools and develop new features and
              functionality that yield even better user experiences.
            </p>
            <p className="company__2-content-heading2">Proctoring Platform</p>
            <p className="company__2-content-subheading">
              Our proctoring platform, the first of its kind to offer live
              remote proctoring services at scale, is built on advanced
              technology and backed by the largest certified proctor and support
              workforce in the world. Unlike other solutions, our platform
              combines the most efficient aspects of remote proctoring
              technology with specialized human oversight and support to create
              a testing atmosphere that prevents cheating, reduces
              administrative workload, and helps build equitability. The Kyron
              Proctoring Platform gives you three service lines to choose from
              so that you can curate a solution that meets your organization’s
              specific goals.
            </p>
            <p className="company__2-content-heading2">Kyron Exam Platform</p>
            <p className="company__2-content-subheading">
              The Kyron Exam Platform lets you manage every stage of the exam
              process – design, development, delivery, scoring, and reporting –
              all in one place. From creating blueprints to writing items,
              assembling forms, and scoring exams, our platform is the only
              system on the market that gives you complete control over your
              exam content without having to jump through hoops to request
              access. And unlike other exam drivers, our platform can be used as
              a workflow management tool and integrated with existing item
              banks, which makes importation easier and eliminates publishing
              fees.
            </p>
          </div>
        </div>
        {/* <div className="resource__1">
          <div className="resource__1-imagebox">
            <img loading="lazy" className="resource__1-image" src={Lab} alt="Lab" />
          </div>

          <div className="resource__1-content">
            <h3 className="resource__1-content-heading">
            About Us
          </h3> 
            <p className="resource__1-content-subheading">
              Signal Sciences and Artificial Intelligence (SigSAIL) Pvt Ltd is a
              startup incubated at IIITD Innovation and Incubation Center
              (IIITDIC), Delhi (https://iiitdic.in/) on November 10, 2020. It is
              headed by Dr. Rajiv Ratn Shah (https://iiitd.ac.in/rajivratn),
              Assistant Professor in the Departments of Computer Science &
              Engineering and Human Centered Design at the Indraprastha
              Institute of Information Technology, Delhi (IIITD), which is a
              state university located in Delhi, India. More details of IIITDIC
              startups can be found at (https://iiitdic.in/our-startups/).
              Quality AI development is expensive and requires significant
              hands-on experience. Building an AI team for your projects is
              unfeasible and it is difficult to get well-qualified AI
              researchers working in your area. With this motivation,
              researchers from IIITD have come together to form SigSAIL. We
              assist companies of all scales in building their AI-enabled
              products and services. SigSAIL provides the services of an expert
              team at a fraction of the original cost and hassle. We have
              experts in a variety of areas working on a host of industry
              problems. For instance, we have provided AI-enabled EduTech
              solutions to help clients in automating test evaluation and
              provide feedback to their test-takers. These systems now are
              serving &gt;10k test-taker per month worldwide, thus saving our
              clients significant cost and enhancing their customer experience
              and turn-around time.
               Kyron is the state of the art system designed and programmed by
              computer science researchers, organizational behavior and human
              psychology experts to find the perfect talent for every job and
              finding appropriate jobs for a candidate's skill set. <br /> <br /> Kyron’s
              journey starts with the skill identification for each job profile
              and our AI algorithm searches for the appropriate set of
              candidates from the candidate pool. The candidates then go through
              a uniquely curated and personalized assessment and screening
              process. The reports are then forwarded to the interviewers. <br /> <br /> Not
              just that!! Kyron maintains the test integrity through our
              revolutionary Human & AI proctor combo, while assuring that
              candidate experience is exceptional at each step. <br /> <br /> Welcome to the
              future of Recruiting Science… 
            </p>
          </div>
        </div> */}
        <h3 className="section-company__heading">Collaborations</h3>
        <div className="customer-cards">
          <div className="customer-card" onClick={openIIITD}>
            <img
              loading="lazy"
              className="customer-card__image"
              src={Iitd}
              alt="Iitd"
            />
            <h4 className="customer-card__title">
              Indraprastha Institute of Information Technology (IIIT) Delhi
            </h4>
            <div className="customer-card__link">Read more...</div>
          </div>
          <div className="customer-card" onClick={openMidas}>
            <img
              loading="lazy"
              className="customer-card__image"
              src={Midas}
              alt="Midas"
            />
            <h4 className="customer-card__title">
              MIDAS: Multimodal Digital Media Analysis Lab
            </h4>
            <div className="customer-card__link">Read more...</div>
          </div>
          <div className="customer-card" onClick={openIITDInovation}>
            <img
              loading="lazy"
              className="customer-card__image"
              src={Incubation}
              alt="Incubation"
            />
            <h4 className="customer-card__title">
              IIITD Innovation and Incubation Center
            </h4>
            <div className="customer-card__link">Read more...</div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default CompanyPage;
