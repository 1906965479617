import styles from "./AdobeLandingPage.module.scss";
import Banner from "../../assets/images/banner.jpg";
import SheCodesPoster from "../../assets/images/shecodes-poster.jpeg";
import Logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";

function AdobeLandingPage() {
  const history = useNavigate();

  return (
    <>
      <div className={`${styles.parallaxContainer}`}>
        <div className={` ${styles.overlayBox}`}>
          <h2>
            Come create experiences that matter at a<br />
            company that is recognized around the world
          </h2>
          <a
            onClick={() => history("/shecodes")}
            className={styles.applyButton}
          >
            Apply Now
          </a>
        </div>
        <div
          className={styles.parallax}
          style={{ backgroundImage: `url(${Banner})` }}
        ></div>
      </div>
      <div className={`${styles.ourCompany}  container`}>
        <div className={styles.ourCompanyText}>
          <h3>Our Company</h3>
          <p>
            Changing the world through digital experiences is what Adobe’s all
            about. We give everyone—from emerging artists to global
            brands—everything they need to design and deliver exceptional
            digital experiences. We’re passionate about empowering people to
            create beautiful and powerful images, videos, and apps, and
            transform how companies interact with customers across every screen.
          </p>
          <p>
            We’re on a mission to hire the very best and are committed to
            creating exceptional employee experiences where everyone is
            respected and has access to equal opportunity. We realize that new
            ideas can come from everywhere in the organization, and we know the
            next big idea could be yours.
          </p>
        </div>
        <div className={styles.ourCompanyVideo}>
          <iframe
            src="https://www.youtube.com/embed/cE-zMHGq-i8"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="video"
            height={"100%"}
            width={"100%"}
          />
        </div>
      </div>
      <div className={`${styles.jobOpening} container`}>
        <h3>Job Opening</h3>
        <div className={styles.sheCodePoster}>
          <img loading="lazy" src={SheCodesPoster} alt="SheCodesPoster" />
        </div>
      </div>
      <div
        className={`${styles.jobOpening2} `}
        style={{
          background: `#0e0e10 url(${
            process.env.PUBLIC_URL + "/img/adobe-bg2.png"
          }) no-repeat top right`,
        }}
      >
        <div className="container">
          <h1>Product Intern</h1>
          <h5>Location - Noida/ Bengaluru/ Remote India</h5>
          <h5>Stipend :- INR 1,00,000 per month</h5>

          <div className={styles.whatYouDo}>
            <h6>What you’ll do</h6>
            <p>
              The Product Intern position involves White Box testing wherein you
              will
            </p>
            <ul>
              <li>Review engineering requirements and design documents</li>
              <li>Anticipate, procure and configure test setup</li>
              <li>
                Perform in-depth testing, including writing and executing test
                scripts and test specifications
              </li>
              <li>Prepare test strategy for the feature assigned</li>
              <li>Develop or adapt testing tools for functional areas</li>
              <li>
                Innovative approach, excellent problem-solving skills, effective
                articulation and genuine team player.
              </li>
            </ul>
          </div>
          <div className={styles.whatYouDo}>
            <h6>Eligibility</h6>
            <ul>
              <li>Students in penultimate year BTech or Integrated MTech </li>
              <li>Impeccable academic record</li>
              <li>Availability to intern for 10-12 weeks starting May’23</li>
            </ul>
          </div>
          <div className={styles.whatYouDo}>
            <h6>Career Path</h6>
            <p>
              When you join Adobe, you can look forward to collaborating with
              the most genuine people in the industry, working on projects with
              real purpose, and having immense pride in the products we create
              and the customers we support. You will also be surrounded by
              colleagues who are committed to helping each other grow through
              our unique Check-In approach where ongoing feedback flows freely.
            </p>
          </div>
          <a onClick={() => history("/shecodes")} className={styles.apply}>
            Apply Now
          </a>
        </div>
      </div>

      <div className={`${styles.adobeForAll} container`}>
        <h3>Adobe For All</h3>
        <p>
          At Adobe, we believe that when people feel respected and included they
          can be more creative, innovative, and successful.
        </p>

        <p>
          While we have more work to do to advance diversity and inclusion,
          we’re investing to move our company and industry forward. We call this
          Adobe For All - Be extraordinary together (#AdobeForAll)
        </p>
        <p>
          A complex challenge requires a multidimensional approach to drive real
          change. To advance our vision of Adobe For All, we’re investing
          strategically in key areas to drive greater diversity and inclusion at
          Adobe and beyond.
        </p>
        <div className={`${styles.whiteBox} `}>
          <ul className={styles.whiteBox__ul}>
            <li className={styles.whiteBox__ul__li}>
              Building the talent pipeline - Since the future of Adobe and every
              other tech company depends on our ongoing ability to hire talented
              people with diverse perspectives, we need more women and members
              of underrepresented groups to pursue careers in technology. That’s
              why we’re working to expand access to education and real-world
              experience for underserved and underrepresented youth. We’ve
              partnered with Girls Who Code, Technovation and Reboot
              Representation Tech Coalition, organizations committed to teaching
              computer science skills to girls.
            </li>
            <li className={styles.whiteBox__ul__li}>
              Attracting diverse candidates - Diversity of thought and
              experience strengthens our teams and helps us create great
              products and services for our diverse customers around the world.
              In order to attract, hire, and develop candidates of all genders,
              ethnicities, and backgrounds, we’ve evolved our recruiting
              practices to mitigate bias. We source talent from a wide variety
              of partners and institutions to broaden our reach. And our Adobe
              Digital Academy is helping nontraditional candidates transition to
              careers in tech.
            </li>
          </ul>
        </div>
        <p>
          Adobe is an equal opportunity employer. We hire talented individuals,
          regardless of gender, race, ethnicity, ancestry, age, disability,
          sexual orientation, gender identity or expression, veteran status,
          cultural background or religious beliefs. We know that when our
          employees feel appreciated and included, they can be more creative,
          innovative and successful. This is what it means to be Adobe For All.
        </p>
        <div className={styles.readMoreButton}>
          <a href={`https://www.adobe.com/diversity.html`} target="__blank">
            Read More
          </a>
        </div>
      </div>

      <div className={`${styles.videosContainer} `}>
        <div className="container">
          <h3>Videos</h3>
          <div className={`${styles.videosBox} `}>
            <iframe
              width="100%"
              height="250"
              src="https://www.youtube.com/embed/29cGh-5WqCo?rel=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
            ></iframe>
            <iframe
              width="100%"
              height="250"
              src="https://www.youtube.com/embed/6acT3nCS_wc?rel=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
            ></iframe>
            <iframe
              width="100%"
              height="250"
              src="https://www.youtube.com/embed/NxSC8ARsWMM?rel=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
            ></iframe>
          </div>
        </div>
      </div>

      <div className={`${styles.galleryContainer} container`}>
        <h3>Gallery</h3>
        <div className={styles.row}>
          <div className={styles.column}>
            <img src="https://www.firstnaukri.com/careers/customised/landingpage/adobe/images/gallery/1.jpg" />
            <img src="https://www.firstnaukri.com/careers/customised/landingpage/adobe/images/gallery/4.jpg" />
          </div>
          <div className={styles.column}>
            <img src="https://www.firstnaukri.com/careers/customised/landingpage/adobe/images/gallery/3.jpg" />
            <img src="https://www.firstnaukri.com/careers/customised/landingpage/adobe/images/gallery/2.jpg" />
          </div>
          <div className={styles.column}>
            <img src="https://www.firstnaukri.com/careers/customised/landingpage/adobe/images/gallery/5.jpg" />
            <img src="https://www.firstnaukri.com/careers/customised/landingpage/adobe/images/gallery/6.jpg" />
          </div>
          <div className={styles.column}>
            <img src="https://www.firstnaukri.com/careers/customised/landingpage/adobe/images/gallery/7.jpg" />
            <img src="https://www.firstnaukri.com/careers/customised/landingpage/adobe/images/gallery/8.jpg" />
          </div>
        </div>
      </div>
      <div className={`${styles.blogContainer} container`}>
        <h3>
          What our employees are saying about their career <br /> experiences on
          the Adobe Life blog.
        </h3>
        <a
          href={`http://blogs.adobe.com/adobelife/category/apac/`}
          target="__blank"
          className={styles.readMoreButton}
        >
          Read more about Adobe life
        </a>
      </div>
      <div className={`${styles.footer} `}>
        <div className={`${styles.footerBox} container`}>
          <div>
            <p>
              Content provided by <span>Adobe</span>
            </p>
            <p>Kyron disclaims all warranties against infringement.</p>
          </div>
          <img src={Logo} alt="logo" loading="lazy" className="navbar-logo" />
        </div>
      </div>
    </>
  );
}

export default AdobeLandingPage;
