import styles from "./MidasEssay.module.scss";

import EssayPoster from "../../assets/images/essay-poster.jpeg";

function MidasEssay() {
  return (
    <>
      <div className={`${styles.container} container`}>
        <img loading="lazy" src={EssayPoster} alt="EssayPoster" />
      </div>
      <div className="container">
        <p className={styles.text}>
          <b>MIDAS Lab, IIIT Delhi</b> एक डिजिटल हिंदी निबंध लेखन प्रतियोगिता का
          आयोजन कर रहा है। MIDAS भारतीय भाषाओं के अनुसंधान के लिए आर्टिफिशियल
          इंटेलिजेंस (AI) और प्राकृतिक भाषा प्रसंस्करण (NLP) के लिए अग्रणी
          प्रयोगशालाओं में से एक है। सभी प्रतिभागियों को भागीदारी प्रमाण पत्र
          दिया जाएगा। इस निबंध प्रतियोगिता में प्रथम, द्वितीय व तृतीया पुरस्कार
          प्राप्त करने वाले विद्यार्थियों को क्रमशः 2000, 1500, व 1000 रुपये
          प्रदान किया जायेगा। साथ ही कुछ विद्यार्थियों को प्रोत्साहन पुरस्कार के
          तौर पे 500 रुपये प्रदान किया जायेगा। यह प्रतियोगिता{" "}
          <b>25 August, 2022</b> को समाप्त होगी। <br /> <br /> आपके निबंध हिंदी
          के लिए विभिन्न एनएलपी सिस्टम विकसित करने में हमारी मदद करेंगे जैसे{" "}
          <b>(i) स्वचालित निबंध मूल्यांकन</b>,{" "}
          <b>(ii) स्वचालित व्याकरण सुधार</b>,
          <b>(iii) हिंदी लेखन पर स्वचालित प्रतिक्रिया</b>,{" "}
          <b>(iv) स्वचालित मशीन अनुवाद</b>,<b>(v) स्वचालित टेक्स्ट जनरेशन</b>,{" "}
          <b>(vi) प्राकृतिक भाषा की स्वचालित समझ</b>, 
          <b> (vii) स्वचालित प्रश्न उत्तर</b>,{" "}
          <b>(viii) मुख्य शब्द निष्कर्षण और जनरेशन</b>,
          <b>(ix) स्वचालित सूचना पुनर्प्राप्ति</b> और कई अन्य। निबंध प्रतियोगिता
          में भाग लेने और आर्टिफिशियल इंटेलिजेंस (AI) द्वारा भारतीय भाषाओं के
          अनुसंधान में योगदान देने के लिए आप का कोटि-कोटि धन्यवाद्। <br />{" "}
          <br />
          हमारे पास छह निबंध विषय हैं। आप हिंदी निबंध लेखन का आनंद लेने के लिए
          जितने चाहें उतने विषयों पर निबंध लिख सकते हैं और प्रतियोगिता में जीतने
          की संभावना भी बढ़ा सकते हैं। विभिन्न निबंध विषयों के लिंक इस प्रकार
          हैं। <br />
          <br /> निबंध 1 [विषय: यात्रा और अनुभव]{" "}
          <a href="https://rb.gy/xycprx">https://rb.gy/xycprx</a> <br />
          <br /> निबंध 2 [विषय: शिक्षा पर महामारी का प्रभाव]{" "}
          <a href="https://rb.gy/wfsm7d">https://rb.gy/wfsm7d</a> <br />
          <br /> निबंध 3 [विषय: मेहनत और किस्मत]{" "}
          <a href="https://rb.gy/5irzco">https://rb.gy/5irzco</a> <br />
          <br /> निबंध 4 [विषय: 21वीं सदी की तकनीक के प्रभाव]
          <a href="https://rb.gy/nhapbl">https://rb.gy/nhapbl</a> <br />
          <br /> निबंध 5 [विषय: वह तोड़ना जानती है|​]
          <a href="https://rb.gy/lnvxso">https://rb.gy/lnvxso</a> <br />
          <br /> निबंध 6 [विषय: भारत के लिए डॉ कलाम के सपने]
          <a href="https://rb.gy/7pnfut">https://rb.gy/7pnfut</a> <br />
          <br /> हम आपकी भागीदारी की प्रतीक्षा कर रहे हैं।
          <br /> डॉ राजीव रत्न शाह <br /> Director, MIDAS Lab
        </p>
      </div>
    </>
  );
}

export default MidasEssay;
