import React from "react";
import { useEffect, useState } from "react";
import Footer from "../components/footer";

import Skills from "../assets/icons/skill.png";
import Assessment from "../assets/icons/assessment.png";
import Platform from "../assets/icons/platform.png";
import Tool from "../assets/icons/tool.png";
import Proctor from "../assets/icons/proctor.png";
import Tracking from "../assets/icons/tracking.png";

import Platform1 from "../assets/images/platform1.png";
import Platform11 from "../assets/images/platform11.png";
import Platform2 from "../assets/images/platform2.png";
import Platform21 from "../assets/images/platform21.png";
import Proctoring from "../assets/images/proctoring.png";
import { Hiring2 } from "../components/hiring";

function ProductPage() {
  const scroll = (id) => {
    const section = document.querySelector(`#${id}`);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  // The back-to-top button is hidden at the beginning
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    <div>
        <h3 className="skill-section__heading">
            <span>Kyron Demo Video</span>
          </h3>
          <div className="skill-section__video-container">
            <video
              src="https://kyron.in/demo.mp4"
              controls
              autoPlay
              className="skill-section__video"
            ></video>
          </div>

      <div className="test u-margin-top-huge">
        <div className="product-box">
          <p className="product-box__heading">
            Kyron presents a state-of-the-art candidate assessment and
            onboarding platform that helps the companies in hiring suitable
            talents. First, it discovers talented candidates who have
            appropriate skills. Next, these candidates go through our proctored
            exam platform. Finally, right candidates having desired skills get
            selected for the company.
          </p>
          <div class="funnel-wrap">
            <ul className="funnel-wrap-list">
              <li
                className="funnel-wrap-list-item"
                onClick={() => scroll("skill")}
              >
                Skill Inventory
              </li>

              <li
                className="funnel-wrap-list-item"
                onClick={() => scroll("talent")}
              >
                Talent Discovery
              </li>
              <li
                className="funnel-wrap-list-item"
                onClick={() => scroll("platform")}
              >
                Exam platform & proctoring
              </li>
              <li
                className="funnel-wrap-list-item"
                onClick={() => scroll("assessement")}
              >
                Competencies Assessment
              </li>
              <li
                className="funnel-wrap-list-item"
                onClick={() => scroll("applicant")}
              >
                Talent Onboarded
              </li>
            </ul>
          </div>
        </div>

        <section className="skill-section" id="skill">
        
          <h3 className="skill-section__heading">
            <span>Kyron Skill Inventory</span>
          </h3>
          <div className="skill-section-box">
            <ul className="skill-section-box-list">
              <li className="skill-section-box-list-item">
                Don’t fit squares in round holes.
              </li>
              <li className="skill-section-box-list-item">
                Finding the perfect cocktail of skill, behaviors and
                motivational factors for your job.
              </li>
              <li className="skill-section-box-list-item">
                Customized algorithms to find out the competencies and
                evaluation criteria based on industry talent benchmark.
              </li>
              <li className="skill-section-box-list-item">
                First and only company in the geo to provide an exhaustive
                solution about your skill requirements and hire for the right
                skills.
              </li>
              <li className="skill-section-box-list-item">
                We also offer, development centers for advising on upskilling,
                leadership growth and managing expectation of high potential
                talent.
              </li>
            </ul>
            <div className="skill-section-box-imagebox">
              <img
                loading="lazy"
                className="skill-section-box-image"
                src={Skills}
                alt="Skills"
              />
            </div>
          </div>
        </section>
        <section className="skill-section" id="talent">
          <h3 className="skill-section__heading">
            <span>Kyron Talent Discovery</span>
          </h3>
          <div className="skill-section-box">
            <div className="skill-section-box-imagebox">
              <img
                loading="lazy"
                className="skill-section-box-image"
                src={Tool}
                alt="tool"
              />
            </div>
            <ul className="skill-section-box-list">
              <li className="skill-section-box-list-item">
                Magnet for your needle in our talent haystack.
              </li>
              <li className="skill-section-box-list-item">
                Compete for great talent.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Search the world's most diverse and active talent pool
                  </li>
                  <li className="skill-section-box-sublist-item">
                    With over 3.9 lakh resumes updated every month on Kyron, you
                    can reach candidates for nearly any role, industry or
                    location.
                  </li>
                </ul>
              </li>
              <li className="skill-section-box-list-item">
                Zero in on the right talent.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Identify relevant talent with over 25 search filters. Reach
                    out to candidates immediately using email.
                  </li>
                </ul>
              </li>
              <li className="skill-section-box-list-item">
                Keep your pipeline organised.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Organise your pipeline into projects to align with your
                    workflow. Easily monitor candidate responses.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </section>
        <section className="skill-section" id="platform">
          <h3 className="skill-section__heading">
            <span>Kyron Exam Platform</span>
          </h3>
          <div className="skill-section-box">
            <ul className="skill-section-box-list">
              <li className="skill-section-box-list-item">
                Combining Candidate Experience and Test Integrity Into One
                Solution
              </li>
              <li className="skill-section-box-list-item">
                Custom Test at your convenience …. Administered
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Whether you’re creating a blueprint, writing innovative
                    items, administering an exam, or more. Built for every stage
                    of the exam process – design, development, delivery, and
                    analysis – Kyron Exam platform helps you do it all in one
                    place.
                  </li>
                </ul>
              </li>
              <li className="skill-section-box-list-item">
                Develop and manage your exam – without limits.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Tackle every step of the exam creation and assembly process
                    – from item development and banking to form assembly – in a
                    single, secure platform.
                  </li>
                </ul>
              </li>
              <li className="skill-section-box-list-item">
                Reimagine the way you item bank.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Map competency frameworks by customizing item bank
                    hierarchy, and then use the platform as a workflow
                    management tool to ensure that only vetted and approved
                    items make it on your exam. You can also see items formatted
                    in the exact way your test-takers will see them, giving you
                    confidence that your items have been formatted correctly.
                    And because your item bank lives on the same platform as the
                    exam driver, you can publish forms whenever you want without
                    the hassle or expense of “publishing fees.”
                  </li>
                  <li className="skill-section-box-sublist-item">
                    But don’t worry: If you want us to handle the item banking
                    process for you, we can do that too.
                  </li>
                </ul>
              </li>
            </ul>
            <div className="skill-section-box-imagebox">
              <img
                loading="lazy"
                className="skill-section-box-image"
                src={Platform}
                alt="Platform 1"
              />
            </div>
          </div>
          <div className="skill-section-box u-margin-top-big">
            <div className="skill-section-box-imagebox">
              <img
                loading="lazy"
                className="skill-section-box-image"
                src={Platform1}
                alt="Platform 1"
              />
              <img
                loading="lazy"
                className="skill-section-box-image"
                src={Platform11}
                alt="Platform 11"
              />
            </div>
            <ul className="skill-section-box-list">
              <li className="skill-section-box-list-item">
                Deliver your online exam with confidence.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Unlike other exam drivers, our platform is totally
                    customizable. This means you can manage exam settings, like
                    locked or toggled questions, exam time, time reminders, cut
                    scores, competency areas, and more. You can also add extra
                    accommodations and resources, such as calculators, notepads,
                    or helpful links. Plus, you can generate immediate feedback
                    and provide a detailed diagnostic report.
                  </li>
                  <li className="skill-section-box-sublist-item">
                    Want more? Because the platform is fully integrated with the
                    Kyron Proctoring Solution, you can increase exam security
                    and ensure your test-takers have a positive experience.
                  </li>
                </ul>
              </li>
              <li className="skill-section-box-list-item">
                Seamlessly manage your test-takers
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Specify required information when your test-takers are ready
                    to set up their profiles. Then they can buy exams, schedule
                    sessions, and view their results in one central location.
                  </li>
                </ul>
              </li>
              <li className="skill-section-box-list-item">
                Derive greater value from your data and analytics.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Whether you need data spreadsheets or visualizations such as
                    graphs and charts, you can create customized reports and
                    export them to Excel or .csv files.
                  </li>
                </ul>
              </li>
              <li className="skill-section-box-list-item">
                Get full visibility into your exam.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Instead of contacting a support center and requesting exam
                    information, you get 24/7 access to your exam content,
                    test-taker information, and reports. Say hello to
                    transparency and goodbye to traditional gatekeeping
                    policies.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="skill-section-box u-margin-top-big">
            <ul className="skill-section-box-list">
              <li className="skill-section-box-list-item">
                Manage your test-takers with ease.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Gain support from a dedicated account or program manager,
                    who works with you to manage test-taker eligibility and
                    communication. We help your test-takers tackle availability,
                    registration, and scheduling while responding directly to
                    any questions or concerns they may have.
                  </li>
                </ul>
              </li>
              <li className="skill-section-box-list-item">
                Specify your exam day rules.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Create a fair test-taking experience by equipping our
                    proctor and support staff with your program’s unique
                    documentation. From exam start procedures to test-taker
                    rules and security measures, you have the power to set your
                    test-takers up for success.
                  </li>
                </ul>
              </li>
              <li className="skill-section-box-list-item">
                Get help when you need it most.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Need assistance? No problem. You can call our support team
                    for immediate help on exam day. We’re here to answer any
                    questions you may have and walk you through the steps
                    that’ll get you back on track.
                  </li>
                </ul>
              </li>
              <li className="skill-section-box-list-item">
                Transition with ease.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    If you’re moving from a different provider, changing exam
                    drivers, or transitioning to online testing, you can breathe
                    a sigh of relief. We’ve got your back. Our team is here to
                    support you every step of the way – from early decisions to
                    migration and quality control.
                  </li>
                </ul>
              </li>
            </ul>
            <div className="skill-section-box-imagebox">
              <img
                loading="lazy"
                className="skill-section-box-image"
                src={Platform21}
                alt="Platform 21"
              />
              <img
                loading="lazy"
                className="skill-section-box-image"
                src={Platform2}
                alt="Platform 2"
              />
            </div>
          </div>
        </section>

        <section className="skill-section" id="proctoring">
          <h3 className="skill-section__heading">
            <span>Kyron Proctoring</span>
          </h3>
          <div className="skill-section-box">
            <ul className="skill-section-box-list">
              <li className="skill-section-box-list-item">
                You can’t be everywhere! Our proctors can be.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Your test-takers expect convenience. You require security.
                    We guarantee both. We have a full suite of solutions
                    designed to save you time and reduce stress for your
                    test-takers, we can securely proctor your exam – anytime,
                    anywhere.
                  </li>
                </ul>
              </li>
              <li className="skill-section-box-list-item">
                Proctoring Technology.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Exam security is complex. While you can often prevent
                    cheating or detect it in real time, it can also slip through
                    the cracks – regardless of whether you administer your test
                    in person or remotely. When that happens, we’re there to
                    prevent widespread damage. Our psychometricians analyze your
                    exam data through a series of research-backed algorithms to
                    identify suspicious patterns that may indicate fraud.
                  </li>
                </ul>
              </li>
              <li className="skill-section-box-list-item">
                Fulfillment Coordination and Speed.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Our certified proctors review and validate every exam
                    session, covering thousands of hours of footage within 24 to
                    72 hours so you can make fast and accurate scoring
                    decisions.
                  </li>
                </ul>
              </li>
            </ul>
            <div className="skill-section-box-imagebox">
              <img
                loading="lazy"
                className="skill-section-box-image"
                src={Proctor}
                alt="proctoring 1"
              />
            </div>
          </div>
          <div className="skill-section-box u-margin-top-big">
            <div className="skill-section-box-imagebox">
              <img
                loading="lazy"
                className="skill-section-box-image"
                src={Proctoring}
                alt="proctoring 2"
              />
            </div>
            <ul className="skill-section-box-list">
              <li className="skill-section-box-list-item">
                Get insight into test-taker behavior.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    With our expertise, you can identify suspicious test-taker
                    patterns, such as abnormally fast response rates, unusually
                    high scores, or activity that indicates collusion or
                    pre-knowledge.
                  </li>
                </ul>
              </li>
              <li className="skill-section-box-list-item">
                Act on known – or unknown – threats.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Sometimes the biggest threat to exam security hides out of
                    sight – but it’s impossible to hide from data. We inspect
                    your data to pinpoint suspected problems and eradicate
                    threats you didn’t expect.
                  </li>
                </ul>
              </li>
              <li className="skill-section-box-list-item">
                Give your test-takers the benefit of the doubt.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    We examine all your data before flagging a test-taker for
                    possible exam misconduct. And because we use only
                    research-validated techniques to identify potential threats,
                    you can be sure that no test-taker will be flagged
                    erroneously.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="skill-section-box u-margin-top-big">
            <ul className="skill-section-box-list">
              <li className="skill-section-box-list-item">
                Arm yourself with evidence.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    Our process can independently corroborate other pieces of
                    evidence that point to fraud, which means you’re better
                    equipped to make disciplinary decisions.
                  </li>
                </ul>
              </li>
              <li className="skill-section-box-list-item">
                Feel supported every step of the way.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    With us, there is no “black box.” You have full visibility
                    into the analyses we run on your data. We not only walk you
                    through each stage of the process but also make sure you
                    understand the Why and the How.
                  </li>
                </ul>
              </li>
              <li className="skill-section-box-list-item">
                Ensure test-taker privacy.
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    We conduct our data forensics process blindly to protect
                    your program’s reputation and test-takers’ privacy. We
                    remove all test-taker information except ID numbers before
                    analyzing your exam data.
                  </li>
                </ul>
              </li>
              <li className="skill-section-box-list-item">
                The team includes proctors, test-taker advocates, Intervention
                Specialists, technicians, and managers who support the needs of
                the test-taker to ensure a comfortable experience.
              </li>
            </ul>
            <div className="skill-section-box-imagebox">
              <img
                loading="lazy"
                className="skill-section-box-image"
                src={Proctor}
                alt="proctoring 3"
              />
            </div>
          </div>
        </section>

        <section className="skill-section" id="assessement">
          <h3 className="skill-section__heading">
            <span> Kyron Competencies Assessment</span>
          </h3>
          <div className="skill-section-box">
            <ul className="skill-section-box-list">
              <li className="skill-section-box-list-item">
                Automatic Speech Scoring
              </li>
              <li className="skill-section-box-list-item">
                Automatic Essay scoring
              </li>
              <li className="skill-section-box-list-item">
                Grammar Error Identification and Correction
              </li>
              <li className="skill-section-box-list-item">
                Automatic Speech Recognition Systems
              </li>
            </ul>
            <div className="skill-section-box-imagebox">
              <img
                loading="lazy"
                className="skill-section-box-image"
                src={Assessment}
                alt="Assessment 1"
              />
            </div>
          </div>
        </section>
        <section className="skill-section u-margin-bottom-big" id="applicant">
          <h3 className="skill-section__heading">
            <span>Kyron Applicant Tracking</span>
          </h3>
          <div className="skill-section-box">
            <div className="skill-section-box-imagebox">
              <img
                loading="lazy"
                className="skill-section-box-image"
                src={Tracking}
                alt="Tracking 2"
              />
            </div>
            <ul className="skill-section-box-list">
              <li className="skill-section-box-list-item">
                Reduce 80% of Recruiter Workload with the Kyron ATS
              </li>
              <li className="skill-section-box-list-item">
                Faster and better hiring with all-in-one recruitment software.
              </li>
              <li className="skill-section-box-list-item">
                Grammar Error Identification and Correction
              </li>
              <li className="skill-section-box-list-item">
                You are one step away from an applicant tracking system with:
                <ul className="skill-section-box-sublist">
                  <li className="skill-section-box-sublist-item">
                    One-click job posting to multiple-job boards.
                  </li>
                  <li className="skill-section-box-sublist-item">
                    Customizable free career site for employee branding.
                  </li>
                  <li className="skill-section-box-sublist-item">
                    40% faster resume screening with automatic parsing.
                  </li>
                  <li className="skill-section-box-sublist-item">
                    Powerful automation to filter and advance candidates across
                    stages.
                  </li>
                  <li className="skill-section-box-sublist-item">
                    Access to pre-interview screening with integrated testing
                    tools.
                  </li>
                  <li className="skill-section-box-sublist-item">
                    Best interview scheduling features.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </section>

        <Hiring2 />
        <Footer />
      </div>
      {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          &#8593;
        </button>
      )}
    </div>
  );
}

export default ProductPage;
