import React from "react";
import axios from "axios";

import { toast } from "react-toastify";
import { useForm, useField, splitFormProps } from "react-form";
import { baseURL } from "../assets/js/constant";

import { useNavigate } from "react-router-dom";
import {
  validateName,
  validEmail,
  validMobile,
  validateGeneral,
  validateYear,
  validCgpa,
  validPercentage,
  validateJeeRank,
} from "../helper/validation";

const collegeNames = [
  "IIIT Delhi",
  "Delhi Technological University",
  "Netaji Subhash University of Technology",
  "IIIT Bangalore",
  "NIT Jamshedpur",
  "IIIT Hyderabad",
  "BITS Hyderabad",
  "VIT Vellore",
  "UPES",
  "Chandigarh University",
  "DIT Dehradun",
];

async function sendToServer(values, history) {
  await axios
    .post(`${baseURL}/form/adobe-technical-staff`, values)
    .then((response) => {
      toast.success(response.data.msg, {
        position: toast.POSITION.TOP_CENTER,
      });
      history("/events", { replace: true });
    })
    .catch((err) => {
      toast.error(err.response.data.msg, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
}

function JobPage() {
  const history = useNavigate();
  // Use the useForm hook to create a form instance
  const {
    Form,
    meta: { isSubmitting, canSubmit },
  } = useForm({
    onSubmit: async (values, instance) => {
      // onSubmit (and everything else in React Form)
      // has async support out-of-the-box
      await sendToServer(values, history);
      console.log("Huzzah!");
    },
    debugForm: false,
  });

  return (
    <div>
      <section className="section-adobe-form" style={{ height: "100%" }}>
        <div className="section-adobe-form-main">
          <div className="section-adobe-form-main-head">
            <div className="section-adobe-form-main--heading">
              Adobe India Off Campus - Member of Technical Staff - Registration
              link
            </div>
            <div className="section-adobe-form-main--subheading">
              Dear Candidate, <br />
              Thank you for expressing interest in Adobe!
              <br />
              Please fill the below form to register yourself for the program.
              {/* Registrations has been closed. */}
              <br />
              Thanks
            </div>
            <div className="section-adobe-form-foot--text ">
              In case you face any issues, you can reach out to us at &nbsp;
              <a
                href="mailto: sales@kyron.in"
                className="section-adobe-form-link"
              >
                sales@kyron.in
              </a>{" "}
              or &nbsp;
              <a href="tel:+917374091655" className="section-adobe-form-link">
                +91 7374091655
              </a>
              ,&nbsp;
              <a href="tel:+919007287210" className="section-adobe-form-link">
                +91 9007287210
              </a>
            </div>
          </div>
          <Form className="section-adobe-form-main-body form">
            <p className="section-adobe-form-main-body--subheading">
              <span>*</span> Required
            </p>
            <NameField />
            <MobileField />
            <EmailField />
            <GenderField
              field="gender"
              options={["Male", "Female", "Other"]}
              validate={(value) => (!value ? "This is required!" : false)}
            />
            <CollegeNameField />
            {/* <CollegeNameField
              field="college"
              options={collegeNames}
              validate={(value) => (!value ? "This is required!" : false)}
            /> */}
            <GraduationYearField />
            <CurrentProgramField />
            <SpecializationField />
            <CgpaField />
            <ResumeField />
            {!isSubmitting ? (
              <button
                className="form-btn u-margin-top-small"
                type="submit"
                disabled={!canSubmit}
              >
                Submit
              </button>
            ) : (
              <div className="loader"></div>
            )}
          </Form>
          <div className="section-adobe-form-foot">
            <div className="section-adobe-form-foot--text u-margin-bottom-small">
              This content is created by the owner of the form. The data you
              submit will be sent to the form owner. Kyron is not responsible
              for the privacy or security practices of its customers, including
              those of this form owner. Never give out your password.
            </div>
            <div className="section-adobe-form-foot--text ">
              If you are facing any issues please reach out to us at &nbsp;
              <a
                href="mailto: sales@kyron.in"
                className="section-adobe-form-link"
              >
                sales@kyron.in
              </a>{" "}
              or &nbsp;
              <a href="tel:+917374091655" className="section-adobe-form-link">
                +91 7374091655
              </a>
              ,&nbsp;
              <a href="tel:+919007287210" className="section-adobe-form-link">
                +91 9007287210
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

function NameField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("name", {
    validate: validateName,
  });

  return (
    <div className="question-box">
      <label htmlFor="name" className="question-box__label">
        1. Your Name <span>*</span>
      </label>
      <input
        {...getInputProps()}
        type="text"
        placeholder="Enter your name"
        id="name"
        required
        className="question-box__input"
      />
      <label className="question-box__error">
        {" "}
        <label className="question-box__error">
          {" "}
          {isTouched && error ? <em>{error}</em> : null}
        </label>
      </label>
    </div>
  );
}

function EmailField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("email", {
    validate: validEmail,
  });

  return (
    <div className="question-box">
      <label htmlFor="email" className="question-box__label">
        3. Your email address <span>*</span>
      </label>
      <input
        id="email"
        type="email"
        placeholder="Enter your email"
        required
        className="question-box__input"
        {...getInputProps()}
      />
      <label className="question-box__error">
        {" "}
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

function MobileField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("mobile", {
    validate: validMobile,
  });

  return (
    <div className="question-box">
      <label htmlFor="mobile" className="question-box__label">
        2. Your mobile phone number <span>*</span>
      </label>
      <div className="input-box">
        <span>+91</span>
        <input
          id="mobile"
          type="number"
          min="0"
          placeholder="Enter your mobile number"
          required
          className="input-box__input"
          {...getInputProps()}
        />
      </div>

      <label className="question-box__error">
        {" "}
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

function GenderField(props) {
  const [field, fieldOptions, { options, ...rest }] = splitFormProps(props);

  const {
    value = "",
    setValue,
    meta: { error, isTouched },
  } = useField(field, fieldOptions);

  const handleSelectChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="question-box">
      <label htmlFor="gender" className="question-box__label">
        4. Gender <span>*</span>
      </label>
      <select
        {...rest}
        value={value}
        onChange={handleSelectChange}
        className="question-box__input"
      >
        <option disabled value="">
          Select your gender
        </option>
        {["Male", "Female", "Other"].map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <label className="question-box__error">
        {" "}
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

// function CollegeNameField(props) {
//   const [field, fieldOptions, { options, ...rest }] = splitFormProps(props);

//   const {
//     value = "",
//     setValue,
//     meta: { error, isTouched },
//   } = useField(field, fieldOptions);

//   const handleSelectChange = (e) => {
//     setValue(e.target.value);
//   };

//   return (
//     <div className="question-box">
//       <label htmlFor="college" className="question-box__label">
//         5. Your college name <span>*</span>
//       </label>
//       <select
//         id="college"
//         {...rest}
//         value={value}
//         onChange={handleSelectChange}
//         className="question-box__input"
//       >
//         <option disabled value="">
//           Select your college
//         </option>
//         {options.map((option) => (
//           <option key={option} value={option}>
//             {option}
//           </option>
//         ))}
//       </select>

//       <label className="question-box__error">
//         {" "}
//         {isTouched && error ? <em>{error}</em> : null}
//       </label>
//     </div>
//   );
// }

function CollegeNameField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("college", {
    validate: (value) => {
      if (!value) {
        return "College Name is required";
      } else return false;
    },
  });

  return (
    <div className="question-box">
      <label htmlFor="college" className="question-box__label">
        5. Your college name <span>*</span>
      </label>
      <input
        {...getInputProps()}
        type="text"
        placeholder="Enter your college name"
        id="college"
        required
        className="question-box__input"
      />
      <label className="question-box__error">
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

function GraduationYearField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("graduation_year", {
    validate: (val, inst) =>
      validateYear(
        val,
        inst,
        "Answer is required",
        "Please enter a correct year"
      ),
  });

  return (
    <div className="question-box">
      <label htmlFor="graduation" className="question-box__label">
        6. Year of graduation <span>*</span>
      </label>
      <input
        id="graduation"
        type="number"
        placeholder="Enter your answer"
        min="0"
        required
        className="question-box__input"
        {...getInputProps()}
      />

      <label className="question-box__error">
        {" "}
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

function CurrentProgramField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("current_course", {
    validate: (val, inst) => validateGeneral(val, inst, "Answer is required"),
  });

  return (
    <div className="question-box">
      <label htmlFor="program" className="question-box__label">
        7. Your current program/ course/ degree <span>*</span>
      </label>
      <input
        id="program"
        type="text"
        placeholder="Enter your answer"
        required
        className="question-box__input"
        {...getInputProps()}
      />

      <label className="question-box__error">
        {" "}
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

function SpecializationField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("specialization", {
    validate: (val, inst) =>
      validateGeneral(val, inst, "Specialization is required"),
  });

  return (
    <div className="question-box">
      <label htmlFor="spec" className="question-box__label">
        8. Specialization <span>*</span>
      </label>
      <input
        id="spec"
        type="text"
        placeholder="Enter your specialization"
        required
        className="question-box__input"
        {...getInputProps()}
      />

      <label className="question-box__error">
        {" "}
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

function CgpaField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("cgpa", {
    validate: validCgpa,
  });

  return (
    <div className="question-box">
      <label htmlFor="cgpa" className="question-box__label">
        9. CGPA Score until the last semester held <span>*</span>
      </label>
      <input
        id="cgpa"
        type="number"
        placeholder="Enter your cgpa on the scale of 10"
        required
        className="question-box__input"
        {...getInputProps()}
      />

      <label className="question-box__error">
        {" "}
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

function ResumeField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField("resume_link", {
    validate: (val, inst) =>
      validateGeneral(val, inst, "Resume link is required"),
  });

  return (
    <div className="question-box">
      <label htmlFor="resume" className="question-box__label">
        10. Link to resume <span>*</span>
      </label>
      <input
        id="resume"
        type="text"
        placeholder="Enter your answer"
        required
        className="question-box__input"
        {...getInputProps()}
      />

      <label className="question-box__error">
        {" "}
        {isTouched && error ? <em>{error}</em> : null}
      </label>
    </div>
  );
}

export default JobPage;
