import React, { useState, useEffect } from "react";
// import Headroom from "react-headroom";
import Logo from "../assets/logo.png";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [isScroll, setIsScroll] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 50) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    };
  }, []);

  const openAppKyron = () => {
    window.open("https://app.kyron.in");
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <>
      {/* <Headroom disableInlineStyles> */}
      <nav className={isScroll ? "navbar navbar--shadow" : "navbar"}>
        <div className="navbar__content">
          <div className="navbar-box">
            <div className="navbar-logo-holder">
              <NavLink to="/">
                <img
                  src={Logo}
                  alt="logo"
                  loading="lazy"
                  className="navbar-logo"
                />
              </NavLink>
            </div>
            <ul className="navbar-link">
              <li>
                <NavLink
                  to="/recruit"
                  className={({ isActive }) =>
                    isActive
                      ? "active-link navbar-link-item "
                      : "navbar-link-item "
                  }
                >
                  Recruit
                </NavLink>
              </li>
              <li className="navbar-link-menu">
                Academy
                <ul className="navbar-sublink">
                  <li>
                    <NavLink
                      to="/academy"
                      className={({ isActive }) =>
                        isActive
                          ? "active-link navbar-link-item "
                          : "navbar-link-item "
                      }
                    >
                      Courses
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      to="/jobs"
                      className={({ isActive }) =>
                        isActive
                          ? "active-link navbar-link-item "
                          : "navbar-link-item "
                      }
                    >
                      {" "}
                      Jobs{" "}
                    </NavLink>{" "}
                  </li> */}
                </ul>
              </li>
              <li>
                <NavLink
                  to="/science"
                  className={({ isActive }) =>
                    isActive
                      ? "active-link navbar-link-item "
                      : "navbar-link-item "
                  }
                >
                  Science
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/customers"
                  className={({ isActive }) =>
                    isActive
                      ? "active-link navbar-link-item "
                      : "navbar-link-item "
                  }
                >
                  Customers
                </NavLink>
              </li>
              {/* <li>
              <NavLink
                to="/resources"
                className={({ isActive }) =>
                  isActive
                    ? "active-link navbar-link-item "
                    : "navbar-link-item "
                }
              >
                Resources
              </NavLink>
            </li> */}
              <li>
                <NavLink
                  to="/company"
                  className={({ isActive }) =>
                    isActive
                      ? "active-link navbar-link-item "
                      : "navbar-link-item "
                  }
                >
                  Company
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/events"
                  className={({ isActive }) =>
                    isActive
                      ? "active-link navbar-link-item "
                      : "navbar-link-item "
                  }
                >
                  Events
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/faq"
                  className={({ isActive }) =>
                    isActive
                      ? "active-link navbar-link-item "
                      : "navbar-link-item "
                  }
                >
                  FAQ
                </NavLink>
              </li>
            </ul>
          </div>
          <ul className="navbar-right">
            <li className="">
              {" "}
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  isActive
                    ? "active-link navbar-right-item "
                    : "navbar-right-item "
                }
              >
                Contact Us
              </NavLink>
            </li>
            <button className="primary-button" onClick={openAppKyron}>
              Login
            </button>
          </ul>
        </div>
        <div className="navigation">
          {/* <div onClick={setIsNavOpen(!isNavOpen)} className={isNavOpen? "navigation__check": "navigation__uncheck"}> 

          </div>*/}
          <input
            onClick={() => toggleNav()}
            type="checkbox"
            className={isNavOpen ? "navigation__check" : "navigation__uncheck"}
            id="navi-toggle"
          />

          <label htmlFor="navi-toggle" className="navigation__button">
            <span className="navigation__icon">&nbsp;</span>
          </label>

          <div className="navigation__background">&nbsp;</div>

          <nav className="navigation__nav">
            <ul className="navigation__list">
              <li className="navigation__item">
                <NavLink
                  onClick={() => toggleNav()}
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? "navigation__link-active navigation__link "
                      : "navigation__link "
                  }
                >
                  1. Home
                </NavLink>
              </li>
              <li className="navigation__item">
                <NavLink
                  onClick={() => toggleNav()}
                  to="/recruit"
                  className={({ isActive }) =>
                    isActive
                      ? "navigation__link-active navigation__link "
                      : "navigation__link "
                  }
                >
                  2. Recruit
                </NavLink>
              </li>
              <li className="navigation__item">
                <NavLink
                  onClick={() => toggleNav()}
                  to="/academy"
                  className={({ isActive }) =>
                    isActive
                      ? "navigation__link-active navigation__link "
                      : "navigation__link "
                  }
                >
                  3. Academy
                </NavLink>
              </li>
              <li className="navigation__item">
                <NavLink
                  onClick={() => toggleNav()}
                  to="/science"
                  className={({ isActive }) =>
                    isActive
                      ? "navigation__link-active navigation__link "
                      : "navigation__link "
                  }
                >
                  4. Science
                </NavLink>
              </li>
              <li className="navigation__item">
                <NavLink
                  onClick={() => toggleNav()}
                  to="/customers"
                  className={({ isActive }) =>
                    isActive
                      ? "navigation__link-active navigation__link "
                      : "navigation__link "
                  }
                >
                  5. Customers
                </NavLink>
              </li>
              <li className="navigation__item">
                <NavLink
                  onClick={() => toggleNav()}
                  to="/company"
                  className={({ isActive }) =>
                    isActive
                      ? "navigation__link-active navigation__link "
                      : "navigation__link "
                  }
                >
                  6. Company
                </NavLink>
              </li>
              <li className="navigation__item">
                <NavLink
                  onClick={() => toggleNav()}
                  to="/events"
                  className={({ isActive }) =>
                    isActive
                      ? "navigation__link-active navigation__link "
                      : "navigation__link "
                  }
                >
                  7. Events
                </NavLink>
              </li>
              <li className="navigation__item">
                <NavLink
                  onClick={() => toggleNav()}
                  to="/aicts"
                  className={({ isActive }) =>
                    isActive
                      ? "navigation__link-active navigation__link "
                      : "navigation__link "
                  }
                >
                  8. AICTS
                </NavLink>
              </li>
              <li className="navigation__item">
                <NavLink
                  onClick={() => toggleNav()}
                  to="/faq"
                  className={({ isActive }) =>
                    isActive
                      ? "navigation__link-active navigation__link "
                      : "navigation__link "
                  }
                >
                  9. FAQ
                </NavLink>
              </li>
             
              <li className="navigation__item">
                <NavLink
                  onClick={() => toggleNav()}
                  to="/contact"
                  className={({ isActive }) =>
                    isActive
                      ? "navigation__link-active navigation__link "
                      : "navigation__link "
                  }
                >
                  10. Contact Us
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </nav>
      <div className="u-padding-bottom-big"></div>

      {/* </Headroom> */}
    </>
  );
};
export default Navbar;
